import React, {
    useContext,
    createContext,
    useState,
    useEffect,
    useCallback,
    useMemo,
    useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
    User,
    setPersistence,
    getAuth,
    updatePassword,
    signInWithEmailAndPassword,
    browserLocalPersistence,
} from 'firebase/auth';
import { IUserInfo, ResourceRole, RoleLevel, changePwd, getMyInfo } from 'apis/UserApi';
import {
    cleanHostedUser,
    isFirebaseEnabled,
    restoreAuthState,
    saveHostedUser,
    setFirebaseEnabled,
} from 'utils/AuthManager';
import { signin, signout } from 'apis/AuthApi';

export type AuthContextType = {
    isReady: boolean;
    isAuthorized: boolean;
    user: IUserInfo | null;
    isSuperAdmin: boolean;
    hasAccess: (targetRole: ResourceRole, resId?: string) => boolean;
    refreshUser: () => void;
    login: (username: string, password: string) => Promise<boolean>;
    logout: () => void;
    changePassword: (
        oldPassword: string,
        password: string,
    ) => Promise<{ result: boolean; error?: string }>;
    downgrade: (targetRole: ResourceRole) => void;
    setPostLoginPath: (path: string) => void;
};

const authContext = createContext<AuthContextType>({
    isReady: false,
    isAuthorized: false,
    user: null,
} as AuthContextType);

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isReady, setReady] = useState<boolean>(false);
    const [isAuthorized, seAuthorized] = useState<boolean>(false);
    const authUser = useRef<Partial<User> | undefined>(undefined);
    const [user, setUser] = useState<IUserInfo | null>(null);
    const postLoginPath = useRef<string | undefined>();
    const navigate = useNavigate();

    useEffect(() => {
        setFirebaseEnabled(!!process.env.REACT_APP_FIREBASE_PROJ_ID);
        restoreAuthState()
            .then((mUser: any) => {
                if (!!mUser) _setAuthData(mUser, false);
                setReady(true);
            })
            .catch((ex) => setReady(true));
    }, []);

    const setPostLoginPath = (path?: string) => (postLoginPath.current = path);

    async function _fetchMyInfo(): Promise<IUserInfo | null> {
        try {
            const result = await getMyInfo();
            if (!!result.data?.id) {
                const mResources = result.data.resources ?? [];
                mResources.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
                return { ...result.data, resources: mResources };
            } else {
                return null;
            }
        } catch (ex) {
            console.error('Cannot fetch my info', ex);
            return null;
        }
    }

    async function _setAuthData(fbUser: Partial<User> | null, redirect: boolean = true) {
        if (fbUser) {
            seAuthorized(true);
            authUser.current = fbUser;
            const portalUser = await _fetchMyInfo();
            setUser(portalUser);
            if (redirect) {
                const target = postLoginPath.current ?? '/';
                navigate(target, { replace: true });
                postLoginPath.current = undefined;
            }
        } else {
            seAuthorized(false);
            authUser.current = undefined;
            setUser(null);
            cleanHostedUser();
            if (redirect) navigate('/login', { replace: true });
        }
    }

    async function login(username: string, password: string, hosted?: boolean): Promise<boolean> {
        setFirebaseEnabled(!!process.env.REACT_APP_FIREBASE_PROJ_ID);
        try {
            if (isFirebaseEnabled() && !hosted) {
                const auth = getAuth();
                await setPersistence(auth, browserLocalPersistence);
                const userCredential = await signInWithEmailAndPassword(auth, username, password);
                setFirebaseEnabled(true);
                // Signed in to firebase
                _setAuthData(userCredential.user);
            } else {
                const signInResp = await signin({ email: username, password });
                if (signInResp.response?.status !== 200 || !signInResp.data) {
                    throw new Error('Sign-in failed:' + signInResp.response?.status);
                }
                setFirebaseEnabled(false);
                await saveHostedUser(signInResp.data);
                _setAuthData(signInResp.data);
            }
            return true;
        } catch (error: any) {
            if (error?.code?.toString() === 'auth/invalid-credential') {
                return login(username, password, true);
            }
            console.error('Sign in failed: ', error?.code ?? error?.response?.data?.code);
            return false;
        }
    }

    async function logout(): Promise<void> {
        try {
            if (isFirebaseEnabled()) {
                await getAuth().signOut();
            } else {
                await signout();
            }
        } catch (ex) {}
        _setAuthData(null).then(() => {});
    }

    async function changePassword(
        oldPassword: string,
        password: string,
    ): Promise<{ result: boolean; error?: string }> {
        if (!user?.email) return { result: false, error: 'Missing email address.' };
        try {
            if (isFirebaseEnabled()) {
                const userCredential = await signInWithEmailAndPassword(
                    getAuth(),
                    user.email,
                    oldPassword,
                );
                await updatePassword(userCredential.user, password);
                return { result: true };
            } else {
                await changePwd(user.id, { id: user.id, password, oldPassword });
                return { result: true };
            }
        } catch (ex: any) {
            if (ex?.toString().toLowerCase().includes('auth/invalid-credential')) {
                return { result: false, error: 'Incorrect old password' };
            }
            return { result: false, error: ex?.response?.data?.message ?? 'unknown' };
        }
    }

    const isSuperAdmin = useMemo<boolean>(() => {
        return user?.role === 'SUPER_ADMIN';
    }, [user]);

    const hasAccess = useCallback(
        (resRole: ResourceRole, resId?: string): boolean => {
            if (isSuperAdmin) return true;
            if (!resId) {
                // Check max role
                const maxRole = (user?.resources ?? []).reduce(
                    (max, el) => Math.max(max, RoleLevel[el.role]),
                    -Infinity,
                );
                return maxRole >= RoleLevel[resRole];
            } else {
                const mResConf = (user?.resources ?? []).find((el) => el.projId === resId);
                return !!mResConf && RoleLevel[mResConf.role] >= RoleLevel[resRole];
            }
        },
        [user, isSuperAdmin],
    );

    function downgrade(targetRole: ResourceRole) {
        if (process.env.NODE_ENV !== 'development' || !user || !isSuperAdmin) return;
        const mUser = { ...user, role: 'USER' };
        mUser.resources.forEach((el) => {
            el.role = targetRole;
        });
        setUser(mUser as IUserInfo);
        alert('Downgraded to: ' + targetRole);
    }

    function refreshUser(): void {
        _fetchMyInfo()
            .then((info) => {
                setUser(info);
            })
            .catch(() => {});
    }

    return (
        <authContext.Provider
            value={{
                isReady,
                isAuthorized,
                user,
                login,
                logout,
                changePassword,
                isSuperAdmin,
                refreshUser,
                hasAccess,
                downgrade,
                setPostLoginPath,
            }}
        >
            {children}
        </authContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(authContext);
};
