import { PageContainer, ProList } from '@ant-design/pro-components';
import React, { useEffect, useMemo, useState } from 'react';
import { Space, Tag, Button, message, Typography, Flex, List, Popover, QRCode } from 'antd';
import { PlusOutlined, EditOutlined, QrcodeOutlined, ExportOutlined } from '@ant-design/icons';
import { ResourceRole } from 'apis/UserApi';
import AddProjLicenseModule from 'components/dialogs/HandleProjLicenseDialog';
import { useAuth } from 'providers/AuthProvider';
import { useAppState } from 'providers/AppStateProvider';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { LicenseDto } from 'apis/ProjectApi';
const { Paragraph, Text } = Typography;

export const LicenseView = React.memo<{ accessKey: string; accessSecret: string }>(
    (props) => {
        const deepLink = useMemo(() => {
            const jsonPair = JSON.stringify({
                apiKey: props.accessKey,
                secretKey: props.accessSecret,
            });
            const encodedJsonPair = encodeURIComponent(btoa(jsonPair));
            return `locaite://license?key=${encodedJsonPair}`;
        }, [props.accessKey, props.accessSecret]);
        return (
            <>
                <Text type="secondary">Access key:</Text>
                <Paragraph copyable={{ text: props.accessKey }}>
                    {props.accessKey}
                    <Popover content={<QRCode value={props.accessKey} bordered={false} />}>
                        <QrcodeOutlined
                            style={{ marginLeft: 6 }}
                            className="ant-typography-expand"
                        />
                    </Popover>
                </Paragraph>
                <Text type="secondary">Access secret:</Text>
                <Paragraph copyable={{ text: props.accessSecret }}>
                    {props.accessSecret}
                    <Popover content={<QRCode value={props.accessSecret} bordered={false} />}>
                        <QrcodeOutlined
                            style={{ marginLeft: 6 }}
                            className="ant-typography-expand"
                        />
                    </Popover>
                </Paragraph>
                <Space.Compact>
                    <Button
                        size="small"
                        icon={<ExportOutlined />}
                        onClick={() => window.open(deepLink, '_blank')}
                    >
                        Open in Locaite Apps
                    </Button>
                    <Popover content={<QRCode value={deepLink} bordered={false} />}>
                        <Button size="small" icon={<QrcodeOutlined />} />
                    </Popover>
                </Space.Compact>
            </>
        );
    },
    (prev, curr) => prev.accessKey === curr.accessKey && prev.accessSecret === curr.accessSecret,
);

const LicenseListScreen: React.FC = () => {
    const { hasAccess, isSuperAdmin } = useAuth();
    const { activeProject, project, refreshProject, isProjectLoading, isMobile, isTablet } =
        useAppState();
    const [filterLicense, setFilterLicense] = useState<React.Key | undefined>('ALL');

    const isAdmin = hasAccess(ResourceRole.ADMIN, project?.id);

    useEffect(() => {
        if (!!activeProject?.projId) {
            refreshProject(activeProject?.projId);
        }
    }, []);

    const navigate = useNavigate();

    function _renderListItem(row: LicenseDto, index: number) {
        return (
            <List.Item>
                <Flex gap={12} flex={1} vertical={isMobile}>
                    <Flex vertical flex={3}>
                        <LicenseView accessKey={row.accessKey} accessSecret={row.accessSecret} />
                    </Flex>
                    <Flex vertical flex={2}>
                        <Text type="secondary">
                            Expire at:
                            {new Date(row.expiredAt) < new Date() ? (
                                <Tag style={{ marginLeft: 8 }} color="red">
                                    Expired
                                </Tag>
                            ) : undefined}
                        </Text>
                        <Paragraph>
                            <Space>
                                {moment(new Date(row.expiredAt)).format('DD-MMMM-YYYY HH:mm')}
                            </Space>
                        </Paragraph>
                        <Text type="secondary">White list: </Text>
                        <Flex wrap="wrap">
                            {row.whitelist.map((appIds, i) => {
                                return <Tag key={`app-${index}${i}-${appIds}`}>{appIds}</Tag>;
                            })}
                        </Flex>
                    </Flex>
                </Flex>
                <Flex
                    flex={0}
                    key={`lic-ext-group-${index}`}
                    vertical={isTablet ? false : true}
                    align={isTablet ? 'center' : 'stretch'}
                    gap={8}
                >
                    <Tag
                        color={row.enabled ? 'green' : 'lightgrey'}
                        style={{ textAlign: 'center', margin: 0 }}
                    >
                        {row.enabled ? 'ENABLED' : 'DISABLED'}
                    </Tag>
                    {isAdmin && project && row ? (
                        <AddProjLicenseModule
                            projectId={project?.id}
                            record={row}
                            trigger={<Button icon={<EditOutlined />}>Edit</Button>}
                            onSuccess={() => {
                                refreshProject(activeProject?.projId);
                                message.success('license updated');
                            }}
                        />
                    ) : undefined}
                    {isSuperAdmin ? (
                        <Button
                            onClick={() =>
                                navigate('/project/license/config', {
                                    state: { licenseId: row.id },
                                })
                            }
                        >
                            Runtime config
                        </Button>
                    ) : undefined}
                </Flex>
            </List.Item>
        );
    }

    return (
        <PageContainer
            title={'License'}
            subTitle="Project license management"
            breadcrumb={{
                items: [{ title: 'Project' }, { title: 'License' }],
            }}
            loading={{
                spinning: isProjectLoading,
            }}
            extra={[
                isAdmin && project ? (
                    <AddProjLicenseModule
                        key="btn-add-license"
                        projectId={project.id}
                        trigger={
                            <Button type="primary" icon={<PlusOutlined />}>
                                Add license
                            </Button>
                        }
                        onSuccess={() => {
                            refreshProject(activeProject?.projId);
                            message.success('license added');
                        }}
                    />
                ) : undefined,
            ]}
        >
            <ProList
                search={false}
                rowKey="id"
                headerTitle="All license"
                pagination={false}
                showActions="hover"
                itemLayout={isTablet ? 'vertical' : 'horizontal'}
                renderItem={_renderListItem}
                dataSource={project?.licenses.filter(
                    (license) =>
                        filterLicense == 'ALL' || license.enabled == (filterLicense == 'ENABLED'),
                )}
                toolbar={{
                    menu: {
                        activeKey: filterLicense,
                        items: [
                            {
                                key: 'ALL',
                                label: 'All',
                            },
                            {
                                key: 'ENABLED',
                                label: 'Enabled',
                            },
                            {
                                key: 'DISABLE',
                                label: 'Disabled',
                            },
                        ],
                        onChange(key) {
                            setFilterLicense(key);
                        },
                    },
                }}
            />
        </PageContainer>
    );
};

export default LicenseListScreen;
