import { PageContainer, ProCard } from '@ant-design/pro-components';
import React, { useRef, useState } from 'react';
import { Button, Typography } from 'antd';
import { DownloadOutlined, FileZipTwoTone } from '@ant-design/icons';
import { BasicListTier } from 'components/BasicTier';
import { useOutdoorState } from 'providers/OutdoorProvider';
import { getZonalPkg } from 'apis/LandmarkApi';

const OutdoorPackageScreen: React.FC = () => {
    const [exportState, setExportState] = useState<number>(0);
    const { venue } = useOutdoorState();
    const pkgContent = useRef<any>(undefined);
    const _handleExportPackage = (venueId?: string) => {
        if (!venueId) return;
        setExportState(1);
        getZonalPkg(venueId)
            .then((resp) => {
                const contentType = resp.response?.headers?.['content-type'];
                const contentLength = resp.response?.headers?.['content-length'];
                const contentDisposition = resp.response?.headers?.['content-disposition'];
                const fileName =
                    contentDisposition?.split('filename=')[1]?.replace(/"/g, '') || 'package.bin';
                pkgContent.current = {
                    blob: resp.response?.data
                        ? new Blob([resp.response.data], { type: contentType })
                        : undefined,
                    size: Number(contentLength),
                    name: fileName,
                };
                _proceedDownload();
                setExportState(2);
            })
            .catch((ex) => {
                pkgContent.current = undefined;
                setExportState(0);
                console.error(ex);
            });
    };

    const _proceedDownload = () => {
        if (!pkgContent.current?.blob) return;
        const url = window.URL.createObjectURL(pkgContent.current.blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = url;
        a.download = pkgContent.current.name;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const _humanFileSize = (size: number) => {
        var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return +(size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    };

    const _renderContent = () => {
        const isLoading = exportState === 1;
        if (exportState === 2) {
            return (
                <ProCard
                    bordered
                    size="small"
                    style={{ width: 'fitContent', backgroundColor: '#e6f4ffaa' }}
                    className="cursor-pointer"
                    onClick={() => _proceedDownload()}
                >
                    <BasicListTier
                        avatar={<FileZipTwoTone twoToneColor="#aaa" style={{ fontSize: 52 }} />}
                        title={pkgContent.current.name}
                        description={
                            <Button style={{ marginTop: 6 }} icon={<DownloadOutlined />}>
                                Download ({_humanFileSize(pkgContent.current.size)})
                            </Button>
                        }
                    />
                </ProCard>
            );
        } else {
            return (
                <Button
                    icon={<DownloadOutlined />}
                    loading={isLoading}
                    onClick={() => _handleExportPackage(venue?.id)}
                >
                    Export
                </Button>
            );
        }
    };
    return (
        <PageContainer breadcrumb={undefined} title={null} header={{ title: undefined }}>
            <ProCard title="Export zonal package">
                <Typography.Paragraph type="secondary">
                    Please following the Zonal SDK integration guide to setup.
                </Typography.Paragraph>
                <div>{_renderContent()}</div>
            </ProCard>
        </PageContainer>
    );
};

export default OutdoorPackageScreen;
