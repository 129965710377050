import ApiClient from 'utils/NetworkManager';
import { LatLngDto } from './VenueApi';

export interface SourceInfoDto {
    lastSourceAt: Date;
    sourceId: string;
    status: string;
    venueId: string;
}

export interface SourcedVenueDto {
    id: string;
    count: number;
    lastEndAt?: Date;
    name: string;
    center: LatLngDto;
    isNew: boolean;
    source?: SourceInfoDto;
}

export interface JobLogProj {
    timestamp: Date;
    message: string;
}

export interface SourceStatusDto {
    id: string;
    updatedAt: Date;
    status: string;
    logs?: JobLogProj[];
}

export interface TrainingReqDto {
    id: string;
    sourceFrom?: Date;
    sourceTo?: Date;
}

export interface TrainingResultDto {
    id: string
    from: Date;
    to: Date;
    status: string;
    maps: {id?: string, floorIndex: number, submitAt: Date}[];
    updatedAt: Date;
}

export const TrainingState = ['FAIL', 'PENDING', 'GENERATING', 'CONVERTING', 'FORMATTED', 'READY'];

export const getSourcedVenues = () => ApiClient.get<SourcedVenueDto[]>(`/crowd/venues`);
export const getSourcedStatus = (sourceId: string) =>
    ApiClient.get<SourceStatusDto>(`/crowd/status`, { id: sourceId });
export const startTraining = (data: TrainingReqDto) =>
    ApiClient.post<SourceStatusDto>(`/crowd/training/start`, data);
export const getCrowdTaskResults = (venueId: string) =>
    ApiClient.get<TrainingResultDto[]>(`/crowd/results`, { id: venueId });
export const buildCrowedResult = (sourceId: string) =>
    ApiClient.post(`/crowd/build`, { id: sourceId });
