import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { MapContainer, Circle, Marker } from 'react-leaflet';
import { LatLngDto } from 'apis/VenueApi';
import MapTileControl from './MapTileControl';
import L from 'leaflet';

interface IMarkerEntity {
    id: string;
    label?: string;
    lat: number;
    lng: number;
}

type LandmarkMapViewProps = {
    center?: LatLngDto;
    radius?: number;
    onClick?: (item: IMarkerEntity) => void;
    onHover?: (id?: string) => void;
};

export type LandmarkMapViewRef = {
    resetAll: (data?: IMarkerEntity[]) => void;
    selectById: (id?: string) => void;
    getBbox: () => L.LatLngBounds;
};

const MarkerIcon = L.divIcon({
    className: 'markerIcon',
    iconSize: [78, 78],
    html: `<svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M39.36 37.3247C39.2221 37.5468 39.1022 37.7496 39 37.9288C38.8978 37.7496 38.7779 37.5468 38.64 37.3247C38.0961 36.4484 37.2696 35.2677 36.1359 34.0447C34.769 32.5701 32.7682 31.0187 31.3361 29.9083C30.8329 29.5181 30.3999 29.1824 30.0893 28.9236C27.4017 26.6839 25.5512 23.4961 25.0051 20.7241C24.4542 17.9276 24.737 15.0289 25.8176 12.395C26.8982 9.76104 28.7278 7.51055 31.0743 5.92756C33.4208 4.3446 36.1789 3.5 39 3.5C41.8211 3.5 44.5793 4.3446 46.9257 5.92756C49.2722 7.51055 51.1018 9.76104 52.1824 12.395C53.263 15.0289 53.5458 17.9276 52.9949 20.7241C52.4488 23.4961 50.5983 26.6839 47.9107 28.9236C47.6001 29.1824 47.1671 29.5181 46.6639 29.9083C45.2319 31.0187 43.231 32.5701 41.8641 34.0447C40.7305 35.2677 39.9039 36.4484 39.36 37.3247Z" fill="#F71B7E" stroke="white"/><circle cx="39" cy="17.769" r="4" fill="white"/></svg>`,
});

const baseStyle = {
    fillColor: '#3388ff',
    fillOpacity: 1,
    weight: 2,
    color: '#ffffff',
    radius: 5,
    interactive: true,
};

const LandmarkMapView = React.forwardRef<LandmarkMapViewRef, LandmarkMapViewProps>((props, ref) => {
    const [data, setData] = useState<IMarkerEntity[]>([]);
    const [isReady, setIsReady] = useState<boolean>(false);
    const mapRef = useRef<L.Map>();
    const markers = useRef<L.CircleMarker[]>([]);
    const selectedIdx = useRef<number>(-1);

    useEffect(() => {
        if (props.center && mapRef.current) {
            mapRef.current.flyTo(props.center, 20, { duration: 1 });
        }
        setTimeout(() => {
            if (mapRef.current) mapRef.current.invalidateSize();
        }, 100);
    }, [props.center]);

    const _buildListener = (cm: L.CircleMarker, el: IMarkerEntity) => {
        return {
            mouseover: () => cm.setStyle({ ...baseStyle, fillColor: '#FBB03C' }),
            mouseout: () => cm.setStyle({ ...baseStyle, fillColor: '#3388ff' }),
            click: () => {
                if (props.onClick) props.onClick(el);
            },
        };
    };

    const _selectMarker = (id?: string) => {
        if (selectedIdx.current > -1 && selectedIdx.current < markers.current.length) {
            markers.current[selectedIdx.current].setStyle(baseStyle);
            markers.current[selectedIdx.current].on(
                _buildListener(markers.current[selectedIdx.current], data[selectedIdx.current]),
            );
        }
        if (id) {
            const nextIdx = markers.current.findIndex((el) => (el as any).id === id);
            if (nextIdx > -1) {
                markers.current[nextIdx].setStyle({
                    ...baseStyle,
                    fillColor: '#F71B7E',
                    radius: 8,
                });
                markers.current[nextIdx].off();
            }
            selectedIdx.current = nextIdx;
        } else {
            selectedIdx.current = -1;
        }
    };

    useImperativeHandle(ref, () => ({
        resetAll: (data?: IMarkerEntity[]) => setData(data ?? []),
        selectById: (id?: string) => _selectMarker(id),
        getBbox: () => mapRef.current!.getBounds(),
    }));

    const _renderMarkers = () => {
        if (!isReady || !mapRef.current || !data) return;
        if (markers.current.length > 0) {
            for (let m of markers.current) m.removeFrom(mapRef.current);
        }
        const bound = [];
        markers.current = [];
        for (let i = 0; i < data.length; i++) {
            const el = data[i];
            const cm = L.circleMarker([el.lat, el.lng], baseStyle);
            cm.on(_buildListener(cm, el));
            if (el.label) cm.bindTooltip(el.label, { direction: 'bottom' });
            (cm as any).id = el.id;
            cm.addTo(mapRef.current);
            markers.current.push(cm);
            bound.push(cm.getLatLng());
        }
        if (bound.length > 0) {
            mapRef.current.fitBounds(L.latLngBounds(bound), { padding: [100, 100] });
        }
    };

    useEffect(() => {
        selectedIdx.current = -1;
        _renderMarkers();
    }, [data, isReady]);

    return (
        <MapContainer
            ref={mapRef as any}
            center={[22.3193, 114.1694]}
            zoom={15}
            scrollWheelZoom={true}
            zoomControl={false}
            style={{ height: '100%', flex: 1 }}
            whenReady={() => setIsReady(true)}
        >
            <MapTileControl visible={true} />
            {props.center ? (
                <>
                    <Circle
                        center={[props.center.lat, props.center.lng]}
                        radius={props.radius ?? 100}
                        color="#F71B7E"
                        stroke={false}
                        interactive={false}
                    />
                    <Marker
                        icon={MarkerIcon}
                        position={[props.center.lat, props.center.lng]}
                        interactive={false}
                    />
                </>
            ) : (
                <></>
            )}
        </MapContainer>
    );
});

export default React.memo(LandmarkMapView);
