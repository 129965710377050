import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    Typography,
    Divider,
    Space,
    Dropdown,
    Button,
    Spin,
    Tooltip,
    Statistic,
    Row,
    Col,
} from 'antd';
import { DownOutlined, HistoryOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useOutdoorState } from 'providers/OutdoorProvider';
import { getLmStatistics, LmStatisticResultDto, QuartilesDto } from 'apis/LandmarkApi';
import Plot from 'react-plotly.js';
import moment from 'moment';
import { ModalForm, ProCard } from '@ant-design/pro-components';
import PieProgress from 'components/PieProgress';
import ActivityGraph from 'components/ActivityGraph';
const { Text } = Typography;

const weekdayItems = [
    { label: 'Any day', key: '0' },
    { label: 'Sundays', key: '1' },
    { label: 'Mondays', key: '2' },
    { label: 'Tuesdays', key: '3' },
    { label: 'Wednesdays', key: '4' },
    { label: 'Thursdays', key: '5' },
    { label: 'Fridays', key: '6' },
    { label: 'Saturdays', key: '7' },
];

const basePlotConf: Partial<Plotly.Config> = {
    displayModeBar: false,
    responsive: true,
    displaylogo: false,
};
const xLabels = [
    '12am',
    '1am',
    '2am',
    '3am',
    '4am',
    '5am',
    '6am',
    '7am',
    '8am',
    '9am',
    '10am',
    '11am',
    '12pm',
    '1pm',
    '2pm',
    '3pm',
    '4pm',
    '5pm',
    '6pm',
    '7pm',
    '8pm',
    '9pm',
    '10pm',
    '11pm',
];
const plotLayout = {
    autosize: true,
    responsive: true,
    height: 120,
    xaxis: {
        name: 'Hours',
        tickfont: { size: 10, color: 'rgb(107, 107, 107)' },
        ticklen: 2,
        tickvals: [0, 6, 12, 18],
        fixedrange: true,
    },
    yaxis: {
        name: 'Popular time',
        showticklabels: false,
        fixedrange: true,
    },
    barcornerradius: 4,
    bargap: 0.15,
    margin: { t: 12, b: 18, l: 6, r: 6, pad: 0 },
};

const PopularChart = React.memo<{ data: number[] }>(
    (props) => {
        const trace1 = useMemo<Partial<Plotly.Data>>(() => {
            const colors = Array(24).fill('rgb(55, 83, 109)');
            colors[new Date().getHours()] = 'rgb(55, 128, 191)';
            return {
                x: xLabels,
                y: props.data,
                name: '',
                hovertemplate: '<b>%{x}</b>: %{y} visit',
                marker: { color: colors },
                type: 'bar',
            };
        }, [props.data]);
        return (
            <Plot
                style={{ height: 120 }}
                data={[trace1]}
                layout={plotLayout}
                config={basePlotConf}
            />
        );
    },
    (prev, curr) => {
        return JSON.stringify(prev.data) === JSON.stringify(curr.data);
    },
);

const secToTime = (sec: number) => {
    const twoDigit = (val: number) => Math.floor(val).toString().padStart(2, '0');
    return `${twoDigit(sec / 3600)}:${twoDigit((sec % 3600) / 60)}:${twoDigit(sec % 60)}`;
};

const DwellSection = React.memo<QuartilesDto>(
    (props) => {
        const label = useMemo(() => secToTime(props.avg), [props.avg]);
        return (
            <Row gutter={16} style={{ marginTop: 6 }}>
                <Col span={12}>
                    <Statistic title="Avg. dwell time" value={label} />
                </Col>
                <Col span={12}>
                    <Statistic title="Total visited" value={props.count} />
                </Col>
            </Row>
        );
    },
    (prev, curr) => {
        return JSON.stringify(prev) === JSON.stringify(curr);
    },
);

const WeekCalendar: React.FC<{
    data: number[][];
    style?: React.CSSProperties;
    enabled?: boolean;
    title?: boolean;
}> = (props) => {
    const dayName = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const today = new Date();
    const [weekStart, setWeekStart] = useState<Date>(moment(today).startOf('week').toDate());
    const dates = useMemo(() => {
        return Array.from({ length: 7 }, (_, i) => {
            const tempDate = new Date(weekStart);
            tempDate.setDate(weekStart.getDate() + i);
            (tempDate as any).dayOfYear = moment(tempDate).dayOfYear();
            return tempDate;
        });
    }, [weekStart]);

    const _renderContent = useCallback(() => {
        return dates.map((date, index) => {
            const record = props.data.find((el) => el[0] === (date as any).dayOfYear);
            const isToday = date.toDateString() === today.toDateString();
            return (
                <div
                    key={index}
                    style={{
                        flex: 1,
                        color: date.getDay() === 0 ? '#ff4d4f' : date > today ? '#bbbbbb' : 'black',
                        backgroundColor: isToday ? '#eee' : 'transparent',
                        borderRadius: 12,
                        fontWeight: isToday ? 'bold' : undefined,
                    }}
                >
                    <span className="text-08">{dayName[date.getDay()]}</span>
                    <div style={{ padding: '0px 6px', fontSize: 24 }}>
                        {record ? (
                            <PieProgress
                                percent={Math.min(record[3] / record[2], 1) * 100}
                                size={24}
                                color="#4c9dff"
                                backgroundColor="#a3d7ff"
                                title={`${record[2]} visit, ${record[3]} check-in`}
                            />
                        ) : (
                            <PieProgress percent={-1} size={24} backgroundColor="#f7f7f7" />
                        )}
                    </div>
                    <sup className="text-08">
                        {date.getDate()}/{date.getMonth() + 1}
                    </sup>
                </div>
            );
        });
    }, [dates, props.data]);

    const toNextMonth = (next: boolean) => {
        setWeekStart((x) =>
            moment(x)
                .add(next ? 1 : -1, 'week')
                .toDate(),
        );
    };

    const titleLabel = useMemo(() => {
        if (dates[0].toDateString() === moment(today).startOf('week').toDate().toDateString()) {
            return 'This week';
        } else {
            return `${moment(dates[0]).format('ll')} ~ ${moment(dates[6]).format('ll')}`;
        }
    }, [dates]);

    return (
        <div style={props.style} className="w100">
            {props.title != false ? <Text type="secondary">{titleLabel}</Text> : undefined}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    textAlign: 'center',
                }}
                className="calendar-wrapper"
            >
                {props.enabled ? (
                    <div
                        className="cursor-pointer calendar-btn"
                        title="Prev week"
                        onClick={() => toNextMonth(false)}
                    >
                        {'⟨'}
                    </div>
                ) : undefined}
                {_renderContent()}
                {props.enabled ? (
                    <div
                        className="cursor-pointer calendar-btn"
                        title="Next week"
                        onClick={() => toNextMonth(true)}
                    >
                        {'⟩'}
                    </div>
                ) : undefined}
            </div>
        </div>
    );
};

const LandmarkStatsSection: React.FC<{ style?: React.CSSProperties }> = (props) => {
    const { workingLandmark } = useOutdoorState();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [statsResult, setStatsResult] = useState<LmStatisticResultDto>();
    const [selectedDay, setSelectedDay] = useState<string>('0');
    const selectedYear = useRef<number>(new Date().getFullYear());

    const refreshLmStats = useCallback(
        (dayOfWeek?: number, year?: number) => {
            if (!workingLandmark?.id) return;
            const mDayOfWeek = dayOfWeek ?? 0;
            const mYear = year ?? selectedYear.current;
            const filter = {
                dayOfWeek: mDayOfWeek,
                fromDate: new Date(mYear, 0, 1),
                toDate: new Date(mYear, 12, 31),
            };
            setIsLoading(true);
            getLmStatistics(workingLandmark.id, filter)
                .then((resp) => setStatsResult(resp.data))
                .finally(() => setIsLoading(false));
        },
        [workingLandmark],
    );

    useEffect(() => {
        setSelectedDay('0');
        selectedYear.current = new Date().getFullYear();
        if (workingLandmark?.id && !!workingLandmark?.surveyRev) {
            refreshLmStats(0, selectedYear.current);
        } else {
            setStatsResult(undefined);
        }
    }, [workingLandmark]);

    const dayName = useMemo(() => {
        return weekdayItems.find((el) => el!.key === selectedDay)!.label;
    }, [selectedDay]);

    if (!statsResult?.popular) return <></>;
    return (
        <>
            <Divider style={{ margin: 0, borderWidth: 6 }} />
            <ProCard
                style={props.style}
                ghost
                headStyle={{ padding: 0 }}
                title="Popular times"
                subTitle={
                    <Space>
                        <Dropdown
                            menu={{
                                items: weekdayItems,
                                onClick: (info) => {
                                    setSelectedDay(info.key);
                                    refreshLmStats(Number(info.key));
                                },
                            }}
                            trigger={['click']}
                        >
                            <Button
                                type="text"
                                icon={<DownOutlined />}
                                size="small"
                                iconPosition="end"
                            >
                                <Text type="secondary">{dayName}</Text>
                            </Button>
                        </Dropdown>
                        {isLoading ? <Spin size="small" /> : undefined}
                    </Space>
                }
                extra={
                    <Tooltip
                        title={`Based on visits on this place. (${new Date().toLocaleTimeString('en', { timeZoneName: 'short' }).split(' ')[2]})`}
                        className="cursor-pointer"
                    >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                    </Tooltip>
                }
            >
                {statsResult?.popular ? <PopularChart data={statsResult.popular} /> : undefined}
            </ProCard>

            <Divider style={{ margin: 0, borderWidth: 6 }} />
            <ProCard
                style={props.style}
                ghost
                headStyle={{ padding: 0 }}
                title="Visit summary"
                subTitle={<span className="text-08">({selectedYear.current})</span>}
                extra={
                    <Tooltip
                        title="Based on check in and check out time."
                        className="cursor-pointer"
                    >
                        <QuestionCircleOutlined style={{ color: 'grey' }} />
                    </Tooltip>
                }
            >
                <DwellSection {...statsResult.quartiles} />
                <WeekCalendar
                    data={statsResult.visited}
                    style={{ marginTop: 6, marginBottom: 12 }}
                    enabled
                    title
                />

                <center>
                    <ModalForm
                        title={`${selectedYear.current} dwell summary`}
                        trigger={
                            <Button icon={<HistoryOutlined />} shape="round">
                                View history
                            </Button>
                        }
                        submitter={false}
                    >
                        <ActivityGraph
                            key={workingLandmark?.id}
                            data={statsResult.visited}
                            quartiles={[
                                statsResult.quartiles.q1,
                                statsResult.quartiles.q2,
                                statsResult.quartiles.q3,
                            ]}
                            year={selectedYear.current}
                            cellSize={36}
                            tooltips={true}
                            tooltipsFormatter={(val) => (val > 0 ? `(${secToTime(val)})` : '')}
                        />
                    </ModalForm>
                </center>
            </ProCard>
        </>
    );
};

export default LandmarkStatsSection;
