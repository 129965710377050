import { PageContainer, ProCard, ProList, ProListProps } from '@ant-design/pro-components';
import React, { useEffect, useState } from 'react';
import { useVenueState } from 'providers/VenueProvider';
import moment from 'moment';
import { CaretRightOutlined } from '@ant-design/icons';
import { Badge, Button, Flex, message, Progress, Tag, Typography } from 'antd';
import {
    buildCrowedResult,
    getCrowdTaskResults,
    TrainingResultDto,
    TrainingState,
} from 'apis/CrowdApi';
import { VenueType } from 'apis/VenueApi';
import { useNavigate } from 'react-router-dom';

const CrowdSourceTaskScreen: React.FC = () => {
    const { venue } = useVenueState();
    const [data, setData] = useState<TrainingResultDto[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    useEffect(() => {
        if (!!venue) _fetchTasks();
    }, [venue]);

    const _fetchTasks = () => {
        if (!venue) return;
        setLoading(true);
        getCrowdTaskResults(venue.id)
            .then((resp) => {
                setData(resp.data ?? []);
            })
            .finally(() => setLoading(false));
    };

    const _requestBuildSource = (sourceId: string) => {
        buildCrowedResult(sourceId).then((resp) => {
            messageApi.success('Request submitted');
        });
    };

    const columns: ProListProps<TrainingResultDto>['metas'] = {
        title: {
            dataIndex: 'from',
            render: (dom, entity, index) => {
                return (
                    <span style={{ color: 'black' }} title={entity.id}>
                        {moment(entity.from).format('YYYY-MM-DD HH:mm') +
                            ' ~ ' +
                            moment(entity.to).format('YYYY-MM-DD HH:mm')}
                    </span>
                );
            },
        },
        description: {
            dataIndex: 'id',
            render: (dom, entity, index) => {
                if (!entity.maps || entity.maps.length === 0) {
                    return undefined;
                }
                return (
                    <Flex wrap="wrap">
                        <Typography.Text type="secondary" style={{ marginRight: 12 }}>
                            Available floors:{' '}
                        </Typography.Text>
                        {(entity.maps ?? []).map((c, i) => {
                            return (
                                <Tag
                                    className={'rounded-tag ' + c.id ? 'cursor-pointer' : ''}
                                    key={`map-${i}-${c.floorIndex}`}
                                    onClick={() => {
                                        if (c.id) {
                                            navigate(`/project/venue/${venue?.id}/map`, {
                                                state: { mapId: c.id },
                                            });
                                        }
                                    }}
                                    title={c.id}
                                >
                                    {c.floorIndex}
                                </Tag>
                            );
                        })}
                    </Flex>
                );
            },
        },
        extra: {
            dataIndex: 'status',
            render: (status: string, entity: TrainingResultDto) => {
                const mPercent = TrainingState.findIndex((e) => e === status) * 20;
                const [mStatus, mColor] =
                    status === 'READY'
                        ? ['success', '#488d00']
                        : status === 'FAIL'
                          ? ['error', 'red']
                          : ['processing', undefined];
                return (
                    <Flex vertical title={`As of: ${entity.updatedAt}`}>
                        <Badge status={mStatus as any} text={status} />
                        <Progress
                            steps={5}
                            percent={mPercent}
                            size={[20, 4]}
                            showInfo={false}
                            strokeColor={mColor}
                        />
                    </Flex>
                );
            },
        },
        actions: {
            render: (dom, entity, index) => {
                const hasMapId = (entity.maps ?? []).some((el) => !!el.id);
                if (!hasMapId) return undefined;
                return [
                    <Button
                        type="link"
                        size="small"
                        key={`btn-build-${index}`}
                        onClick={() => {
                            _requestBuildSource(entity.id);
                        }}
                    >
                        <CaretRightOutlined />
                        New Build
                    </Button>,
                ];
            },
        },
    };

    if (venue?.type !== VenueType.CROWDSOURCE) {
        return (
            <div style={{ margin: 'auto' }}>
                <p>No crowd sourced task for this venue.</p>
            </div>
        );
    }

    return (
        <PageContainer breadcrumb={undefined} title={null} header={{ title: undefined }}>
            {contextHolder}
            <ProCard bodyStyle={{ padding: 12 }}>
                <ProList
                    loading={loading}
                    rowKey={(record) => `${record.id}-${Math.random()}`}
                    metas={columns}
                    dataSource={data}
                    pagination={{
                        defaultCurrent: 1,
                        defaultPageSize: 100,
                        total: data.length,
                        hideOnSinglePage: true,
                    }}
                    size="small"
                />
            </ProCard>
        </PageContainer>
    );
};

export default CrowdSourceTaskScreen;
