import React, { useMemo, useRef, useState } from 'react';
import { BaseDialogProps } from './BaseDialogProps.type';
import { Alert, Flex, Form, Space } from 'antd';
import {
    ModalForm,
    ProFormDigit,
    ProFormGroup,
    ProFormList,
    ProFormSelect,
    ProFormSwitch,
    ProFormText,
    ProFormTextArea,
} from '@ant-design/pro-components';
import {
    createVenueRuntimeConfig,
    updateVenueActiveBoundConfig,
    updateVenueRuntimeConfig,
    VenueBoundConfigDto,
    VenueRuntimeConfigDto,
    VenueRuntimeConfigEditDto,
} from 'apis/VenueApi';
import FormItem from 'antd/es/form/FormItem';
import CodeEditor, { CodeEditorRef } from 'components/CodeEditor';

const JsonFormInput: React.FC<any> = (props) => {
    return (
        <FormItem
            name={props.name}
            label={props.label}
            required
            rules={[
                { required: true },
                {
                    validator(_, value) {
                        try {
                            JSON.parse(value);
                            return Promise.resolve();
                        } catch (ex) {
                            return Promise.reject(new Error('Invalid JSON format'));
                        }
                    },
                },
            ]}
        >
            <CodeEditor
                ref={props.editorRef as any}
                height={200}
                className="input-bordered"
                {...props}
            />
        </FormItem>
    );
};

export type RuntimeConfigModuleProp = BaseDialogProps & {
    venueId: string;
    config?: VenueRuntimeConfigEditDto;
};
export const RuntimeConfigModule: React.FC<RuntimeConfigModuleProp> = (props) => {
    const editorRef = useRef<CodeEditorRef>();
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [confForm] = Form.useForm();
    const [selectingOS, setSelectingOS] = useState<string>('ANY');
    const isEdit = useMemo(() => !!props.config?.id, [props.config]);

    const _handleSubmit = async (values: any) => {
        setFormLoading(true);
        const contentJsonString = JSON.stringify(JSON.parse(values.content));
        const newRulesObject = {
            brand: selectingOS == 'IOS' ? undefined : values?.brand?.toLowerCase() ?? undefined,
            os: selectingOS,
            osVerFrom: values.osVerFrom != '' ? values.osVerFrom : undefined,
            osVerTo: values.osVerTo != '' ? values.osVerTo : undefined,
            sdkVerFrom: values.sdkVerFrom != '' ? values.sdkVerFrom : undefined,
            sdkVerTo: values.sdkVerTo != '' ? values.sdkVerTo : undefined,
        };

        if (!isEdit) {
            const mData: VenueRuntimeConfigDto = {
                type: 'RUNTIME',
                desc: values.desc,
                content: contentJsonString,
                enabled: values.enabled,
                priority: values.priority,
                rules: newRulesObject,
            };

            try {
                const resp = await createVenueRuntimeConfig(props.venueId, mData);
                if (resp.response?.status === 200 || resp.response?.status === 201) {
                    if (props.onSuccess) props.onSuccess();
                    setFormLoading(false);
                    return true;
                }
            } catch (ex: any) {
                setFormLoading(false);
            }
        } else {
            const mData: VenueRuntimeConfigEditDto = {
                id: props.config!.id,
                type: 'RUNTIME',
                desc: values.desc,
                content: contentJsonString,
                enabled: values.enabled,
                priority: values.priority,
                rules: newRulesObject,
            };

            try {
                const resp = await updateVenueRuntimeConfig(props.venueId, mData);
                if (resp.response?.status === 200 || resp.response?.status === 201) {
                    if (props.onSuccess) props.onSuccess();
                    setFormLoading(false);
                    return true;
                }
            } catch (ex: any) {
                setFormLoading(false);
            }
        }
    };

    const setFormValue = (config: any) => {
        setSelectingOS(config?.rules?.os ?? 'ANY');
        const jsonConf = config?.content
            ? JSON.stringify(JSON.parse(config?.content), null, 4)
            : '';
        confForm.setFieldsValue({
            brand: config?.rules?.brand,
            osVerFrom: config?.rules?.osVerFrom,
            osVerTo: config?.rules?.osVerTo,
            sdkVerFrom: config?.rules?.sdkVerFrom,
            sdkVerTo: config?.rules?.sdkVerTo,
            desc: config?.desc,
            content: jsonConf,
            priority: config?.priority ?? 0,
            enabled: config?.enabled ?? true,
        });
        if (editorRef.current) editorRef.current.setValue(jsonConf);
    };

    return (
        <ModalForm
            form={confForm}
            title={isEdit ? 'Edit venue runtime config' : 'Add venue runtime config'}
            trigger={props.trigger}
            submitter={{
                searchConfig: {
                    submitText: isEdit ? 'Update' : 'Confirm',
                    resetText: 'Cancel',
                },
            }}
            layout="horizontal"
            labelCol={{ span: 4 }}
            labelAlign="left"
            loading={formLoading}
            className="tiny-row"
            modalProps={{
                style: { top: 24, zIndex: 1210 },
                onCancel: () => {
                    confForm.resetFields();
                },
            }}
            onOpenChange={(isOpen) => {
                if (isOpen) {
                    setFormValue(props?.config ?? {});
                }
            }}
            onFinish={_handleSubmit}
        >
            <Form.Item label="Device Platform" style={{ marginBottom: 0 }}>
                <Space.Compact block>
                    <ProFormSelect
                        fieldProps={{
                            defaultValue: 'ANY',
                            value: selectingOS,
                        }}
                        style={{ width: 120 }}
                        onChange={(value) => {
                            setSelectingOS(value);
                        }}
                        options={[
                            { value: 'ANY', label: 'ANY' },
                            { value: 'IOS', label: 'IOS' },
                            { value: 'AOS', label: 'AOS' },
                        ]}
                    />
                    <ProFormText
                        name="osVerFrom"
                        placeholder={'Minimum version'}
                        rules={[
                            {
                                validator(_, value) {
                                    return /^\d+(\.\d+)*$/.test(value) ||
                                        value == null ||
                                        value == ''
                                        ? Promise.resolve()
                                        : Promise.reject(new Error('Invalid version code'));
                                },
                            },
                        ]}
                    />
                    <FormItem
                        style={{
                            width: 20,
                            pointerEvents: 'none',
                            textAlign: 'center',
                            backgroundColor: '#f7f7f7',
                        }}
                    >
                        {'-'}
                    </FormItem>
                    <ProFormText
                        name="osVerTo"
                        placeholder={'Maximum version'}
                        rules={[
                            {
                                validator(_, value) {
                                    return /^\d+(\.\d+)*$/.test(value) ||
                                        value == null ||
                                        value == ''
                                        ? Promise.resolve()
                                        : Promise.reject(new Error('Invalid version code'));
                                },
                            },
                        ]}
                    />
                </Space.Compact>
            </Form.Item>

            {selectingOS == 'IOS' ? undefined : (
                <ProFormText
                    width="md"
                    label="Device brand"
                    name="brand"
                    placeholder={'(Optional) Enter device brand'}
                />
            )}

            <FormItem label="SDK version">
                <Space.Compact block>
                    <ProFormText
                        name="sdkVerFrom"
                        placeholder={'Minimum version'}
                        rules={[
                            {
                                validator(_, value) {
                                    return /^\d+(\.\d+)*$/.test(value) ||
                                        value == null ||
                                        value == ''
                                        ? Promise.resolve()
                                        : Promise.reject(new Error('Invalid version code'));
                                },
                            },
                        ]}
                    />
                    <FormItem
                        style={{
                            width: 20,
                            pointerEvents: 'none',
                            textAlign: 'center',
                            backgroundColor: '#f7f7f7',
                        }}
                    >
                        {'-'}
                    </FormItem>
                    <ProFormText
                        name="sdkVerTo"
                        placeholder={'Maximum version'}
                        rules={[
                            {
                                validator(_, value) {
                                    return /^\d+(\.\d+)*$/.test(value) ||
                                        value == null ||
                                        value == ''
                                        ? Promise.resolve()
                                        : Promise.reject(new Error('Invalid version code'));
                                },
                            },
                        ]}
                    />
                </Space.Compact>
            </FormItem>

            <ProFormTextArea
                label="Description"
                name="desc"
                placeholder={'Enter short description'}
                fieldProps={{ rows: 2 }}
                rules={[{ required: true }]}
            />

            <JsonFormInput name="content" label="Config" editorRef={editorRef} />

            <FormItem label="Priority">
                <Flex gap={48}>
                    <ProFormDigit name="priority" placeholder="Enter the priority" />
                    <ProFormSwitch name="enabled" label="Enable" />
                </Flex>
            </FormItem>
        </ModalForm>
    );
};

export type BoundConfigModuleProp = BaseDialogProps & {
    venueId: string;
    config: VenueBoundConfigDto;
};
export const BoundConfigModule: React.FC<BoundConfigModuleProp> = (props) => {
    const editorRef = useRef<CodeEditorRef>();
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [confForm] = Form.useForm();
    const _handleSubmit = async (values: any) => {
        setFormLoading(true);
        let result = false;

        const finalBeaconList = [];
        if (values.beacons) {
            for (let i = 0; i < values.beacons.length; i++) {
                const bObj = values.beacons[i];
                const idStr = `${bObj.uuid},${bObj.major},${bObj.minor}`;
                finalBeaconList.push({ id: idStr, thres: Number(bObj.thres) });
            }
        }

        const mData: VenueBoundConfigDto = {
            beacons: finalBeaconList,
            radius: values.radius,
            polygon: values.polygon ? JSON.parse(values.polygon) : null,
        };

        try {
            const resp = await updateVenueActiveBoundConfig(props.venueId, mData);
            if (resp.response?.status === 200 || resp.response?.status === 201) {
                if (props.onSuccess) props.onSuccess();
                result = true;
                setFormLoading(false);
                return result;
            } else {
                setFormLoading(false);
                return result;
            }
        } catch (ex: any) {
            setFormLoading(false);
            return result;
        }
    };

    return (
        <ModalForm
            form={confForm}
            title={'Edit bound config'}
            trigger={props.trigger}
            modalProps={{ style: { top: 24 } }}
            submitter={{
                searchConfig: {
                    submitText: 'Update',
                    resetText: 'Cancel',
                },
            }}
            loading={formLoading}
            onOpenChange={(isOpen) => {
                if (isOpen) {
                    confForm.resetFields();
                    const bObjArr = [];
                    for (const bConf of props.config.beacons) {
                        const idStr = (bConf.id ?? '').split(',');
                        if (idStr.length === 3) {
                            bObjArr.push({
                                uuid: idStr[0],
                                major: Number(idStr[1]),
                                minor: Number(idStr[2]),
                                thres: Number(bConf.thres),
                            });
                        }
                    }
                    confForm.setFieldValue('beacons', bObjArr);
                    confForm.setFieldValue('radius', props.config.radius);
                    confForm.setFieldValue('polygon', JSON.stringify(props.config.polygon));
                }
            }}
            onFinish={_handleSubmit}
        >
            <Alert
                message="Warning: The bound configuration may automatically update if the user makes any changes to the floor plan mask or beacon plan. 
            Please proceed with caution as modifying these elements can affect the bound configuration."
                banner
            />
            <ProFormList
                name="beacons"
                label="Beacons"
                className="tiny-row"
                copyIconProps={{ tooltipText: 'Duplicate record' }}
                deleteIconProps={{ tooltipText: 'Remove record' }}
                creatorButtonProps={{ creatorButtonText: 'Add beacons' }}
            >
                <ProFormGroup key="group">
                    <ProFormText name="uuid" label="UUID" width="sm" rules={[{ required: true }]} />
                    <ProFormDigit
                        name="major"
                        label="Major"
                        width="xs"
                        rules={[{ required: true }]}
                    />
                    <ProFormDigit
                        name="minor"
                        label="Minor"
                        width="xs"
                        rules={[{ required: true }]}
                    />
                    <ProFormDigit
                        name="thres"
                        label="Thres"
                        width="xs"
                        rules={[{ required: true }]}
                        max={0}
                        min={-9999}
                    />
                </ProFormGroup>
            </ProFormList>

            <ProFormDigit
                width="md"
                name="radius"
                label="Radius"
                placeholder="Default radius is 100"
                min={0}
                rules={[
                    { required: true },
                    {
                        validator(_, value) {
                            if (value > 0) {
                                return Promise.resolve();
                            } else {
                                return Promise.reject(new Error('Radius must be greater than 0'));
                            }
                        },
                    },
                ]}
            />

            <JsonFormInput
                name="polygon"
                label="Polygon"
                editorRef={editorRef}
                height={100}
                lineWrapping
                placeholder="Enter the venue boundary polygon. i.e. [[lng, lat], [lng, lat], ...]"
            />
        </ModalForm>
    );
};

export type VenuePackageConfigModuleProp = BaseDialogProps & {
    venueId: string;
    config: any;
};
export const VenuePackageConfigModule: React.FC<VenuePackageConfigModuleProp> = (props) => {
    const editorRef = useRef<CodeEditorRef>();
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [confForm] = Form.useForm();
    const isEdit = useMemo(() => !!props.config?.id, [props.config]);

    return (
        <ModalForm
            form={confForm}
            title={'Package config'}
            trigger={props.trigger}
            initialValues={{}}
            submitter={{
                searchConfig: {
                    submitText: isEdit ? 'Update' : 'Confirm',
                    resetText: 'Cancel',
                },
            }}
            loading={formLoading}
            modalProps={{
                onCancel: () => {
                    confForm.resetFields();
                },
            }}
            onOpenChange={(isOpen) => {
                if (props.config) {
                    const jsonValue = JSON.stringify(JSON.parse(props.config.content), null, 4);
                    confForm.setFieldsValue({
                        enabled: props.config.enabled,
                        desc: props.config.desc,
                        content: jsonValue,
                    });
                    if (editorRef.current) editorRef.current.setValue(jsonValue);
                }
            }}
            onFinish={async (values) => {
                setFormLoading(true);
                const contentJsonString = JSON.stringify(JSON.parse(values.content));
                if (!isEdit) {
                    const mData: VenueRuntimeConfigDto = {
                        type: 'PACKAGE',
                        enabled: values.enabled ?? false,
                        desc: values.desc,
                        content: contentJsonString,
                        priority: 1,
                        rules: {},
                    };
                    try {
                        const resp = await createVenueRuntimeConfig(props.venueId, mData);
                        if (resp.response?.status === 200 || resp.response?.status === 201) {
                            if (props.onSuccess) props.onSuccess();
                            setFormLoading(false);
                            return true;
                        }
                    } catch (ex: any) {
                        setFormLoading(false);
                    }
                } else {
                    const mData: VenueRuntimeConfigEditDto = {
                        id: props.config.id,
                        type: 'PACKAGE',
                        enabled: values.enabled ?? false,
                        desc: values.desc,
                        content: contentJsonString,
                        priority: 1,
                        rules: {},
                    };
                    try {
                        const resp = await updateVenueRuntimeConfig(props.venueId, mData);
                        if (resp.response?.status === 200 || resp.response?.status === 201) {
                            if (props.onSuccess) props.onSuccess();
                            setFormLoading(false);
                            return true;
                        }
                    } catch (ex: any) {
                        setFormLoading(false);
                    }
                }
            }}
        >
            <ProFormSwitch name="enabled" label="Enabled" />

            <ProFormText
                label="Short description"
                name="desc"
                placeholder={'Enter a short description'}
                rules={[{ required: true }]}
            />

            <JsonFormInput
                name="content"
                label="Content"
                editorRef={editorRef}
                placeholder="Enter the config JSON"
            />
        </ModalForm>
    );
};
