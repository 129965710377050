import React, { useContext, createContext, useState, useEffect, useLayoutEffect } from 'react';
import { useAuth } from './AuthProvider';
import { ResourceEntry, ResourceRole } from 'apis/UserApi';
import { ProjectDetailDto, ProjectInfoDto, getProject } from 'apis/ProjectApi';
import { useNavigate } from 'react-router-dom';
import { cleanAllImgCache } from 'components/CachedImage';

export interface AppSetting {
    lastProjId: string;
    mapTbMode: boolean;
    layerWaypoint: boolean;
    layerArrow: boolean;
    layerGray: boolean;
    recentProj: string[];
}

export type AppContextType = {
    activeProject?: ResourceEntry;
    project?: ProjectDetailDto;

    switchProject: (projId?: string) => void;
    refreshProject: (projId?: string) => void;
    isProjectLoading?: boolean;

    isMobile: boolean;
    isTablet: boolean;

    navCollapsed: boolean;
    setNavCollapsed: (flag: boolean) => void;

    appSettings: Partial<AppSetting>;
    saveAppSettings: (conf: Partial<AppSetting>) => void;
};

const appContext = createContext<AppContextType>({
    activeProject: undefined,
    switchProject: (projId?: string) => {},
} as AppContextType);

const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [activeProject, setActiveProj] = useState<ResourceEntry>();
    const [project, setProject] = useState<ProjectDetailDto>();
    const [isProjectLoading, setIsProjectLoading] = useState<boolean>();

    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isTablet, setIsTablet] = useState<boolean>(false);
    const [navCollapsed, setNavCollapsed] = useState<boolean>(false);
    const [appSettings, setAppSettings] = useState<Partial<AppSetting>>(_loadAppSettings());

    const { user, isSuperAdmin } = useAuth();
    const navigate = useNavigate();

    useLayoutEffect(() => {
        function updateSize() {
            setIsMobile(window.innerWidth < 598);
            setIsTablet(window.innerWidth < 992);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        // Clean cache
        cleanAllImgCache();
        if (!!user) {
            // Restore last proj
            const pjId = appSettings?.lastProjId;
            if (!activeProject && !!pjId) switchProject(pjId);
        } else {
            setProject(undefined);
            setActiveProj(undefined);
        }
    }, [user]);

    function switchProject(proj: string | ProjectInfoDto | undefined) {
        if (proj === undefined) {
            saveAppSettings({ lastProjId: undefined });
            setActiveProj(undefined);
            setProject(undefined);
            navigate('/');
            return;
        }
        const mProj = typeof proj === 'string' ? { id: proj } : proj;
        // Check user has access
        const projAccess = isSuperAdmin
            ? { projId: mProj.id, role: ResourceRole.ADMIN }
            : (user?.resources ?? []).find((el) => el.projId === mProj.id);
        if (projAccess) {
            setActiveProj(projAccess);
            saveAppSettings({ lastProjId: mProj.id, recentProj: getRecentProj(mProj.id) });

            if (projAccess.role != ResourceRole.VIEWER) {
                refreshProject(mProj.id);
            } else {
                navigate('/');
            }
        }
    }

    function getRecentProj(id: string) {
        const old = appSettings.recentProj ?? [];
        if (old.includes(id)) {
            const index = old.indexOf(id);
            old.splice(index, 1);
        }
        old.push(id);
        if (old.length > 6) old.shift();
        return old;
    }

    function refreshProject(id?: string) {
        if (isProjectLoading) return;
        const projId = id ?? activeProject?.projId;
        if (!projId) {
            console.warn('Missing project id');
            return;
        }
        setIsProjectLoading(true);
        setProject(undefined);
        getProject(projId)
            .then((res) => {
                if (res.data) setProject(res.data);
                setIsProjectLoading(false);
            })
            .catch(() => {
                setProject(undefined);
                setIsProjectLoading(false);
            });
    }

    function saveAppSettings(conf: Partial<AppSetting>) {
        const mConf = { ...appSettings, ...conf };
        localStorage.setItem('lk-app-conf', JSON.stringify(mConf));
        setAppSettings(mConf);
    }
    function _loadAppSettings(): Partial<AppSetting> {
        const res = localStorage.getItem('lk-app-conf');
        try {
            const out = !!res && res?.length > 0 ? JSON.parse(res) : {};
            if (out.mapTbMode === undefined) {
                out.mapTbMode = true;
                localStorage.setItem('lk-app-conf', JSON.stringify(out));
            }
            return out;
        } catch (ex) {
            const out = { mapTbMode: true };
            localStorage.setItem('lk-app-conf', JSON.stringify(out));
            return out;
        }
    }

    return (
        <appContext.Provider
            value={{
                activeProject,
                switchProject,
                project,
                refreshProject,
                isProjectLoading,
                isMobile,
                isTablet,
                appSettings,
                saveAppSettings,
                navCollapsed,
                setNavCollapsed,
            }}
        >
            {children}
        </appContext.Provider>
    );
};

export default AppContextProvider;

export const useAppState = () => {
    return useContext(appContext);
};
