import {
    ModalForm,
    ProForm,
    ProFormText,
    ProFormSwitch,
    ProFormTextArea,
    ProFormDigit,
    ProFormGroup,
    ProFormSelect,
} from '@ant-design/pro-components';
import { Form, Typography, message, Upload, Space, Button } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import { LoadingOutlined, FileZipOutlined, UploadOutlined } from '@ant-design/icons';
import {
    VenueCreateDto,
    VenueDto,
    VenuePackageCreateDto,
    VenuePackagePublishDto,
    createVenue,
    deleteVenue,
    updateVenue,
    cloneVenue,
    createVenuePackage,
    publishVenuePackage,
    VenueType,
} from 'apis/VenueApi';
import type { UploadProps } from 'antd';
import { BaseDialogProps } from './BaseDialogProps.type';
const { Text, Paragraph } = Typography;
import NetworkManager from 'utils/NetworkManager';
import { useAuth } from 'providers/AuthProvider';
import { ResourceRole } from 'apis/UserApi';
import LocationPicker from 'components/maps/LocationPicker';

export type AddVenueProp = BaseDialogProps & {
    projectId: string;
    isEdit: boolean;
    editingVenue: any;
};
export const AddProjVenueModule: React.FC<AddVenueProp> = (props) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const mapRef = useRef<any>();
    const [venueForm] = Form.useForm();
    const isEdit = props.isEdit;
    const editingVenue = props.editingVenue;

    const _handleSubmit = async (values: any) => {
        setFormLoading(true);
        let result = false;

        const finalCenter = { lat: values.latitude, lng: values.longitude };
        if (!isEdit) {
            const mData: VenueCreateDto = {
                projectId: props.projectId,
                name: values.name,
                center: finalCenter,
                type: values.indoor ? VenueType.INDOOR : VenueType.OUTDOOR,
            };
            try {
                const resp = await createVenue(mData);
                if (resp.response?.status === 200 || resp.response?.status === 201) {
                    if (props.onSuccess) props.onSuccess();
                    result = true;
                }
            } catch (ex: any) {}
        } else {
            const mData: VenueDto = {
                projectId: props.projectId,
                name: values.name,
                center: finalCenter,
                enabled: values.enabled,
                id: editingVenue.id,
                type: values.indoor ? VenueType.INDOOR : VenueType.OUTDOOR,
            };

            try {
                const resp = await updateVenue(editingVenue.id, mData);
                if (resp.response?.status === 200 || resp.response?.status === 201) {
                    if (props.onSuccess) props.onSuccess();
                    result = true;
                }
            } catch (ex: any) {}
        }

        setFormLoading(false);
        return result;
    };

    const _updateMapCenter = () => {
        mapRef.current.flyTo({
            lat: venueForm.getFieldValue('latitude'),
            lng: venueForm.getFieldValue('longitude'),
        });
    };

    return (
        <ModalForm
            form={venueForm}
            title={isEdit ? 'Edit venue' : 'Add venue'}
            className="tiny-row"
            trigger={props.trigger}
            initialValues={
                isEdit
                    ? {
                          name: editingVenue.name,
                          latitude: editingVenue.center.lat,
                          longitude: editingVenue.center.lng,
                          enabled: editingVenue.enabled,
                          indoor: editingVenue.type !== VenueType.OUTDOOR,
                      }
                    : undefined
            }
            submitter={{
                searchConfig: {
                    submitText: isEdit ? 'Update' : 'Confirm',
                    resetText: 'Cancel',
                },
            }}
            loading={formLoading}
            onOpenChange={(isOpen) => {
                if (isOpen) {
                    venueForm.resetFields();
                }
            }}
            onFinish={_handleSubmit}
        >
            {isEdit ? (
                <Paragraph type="secondary">
                    <span>ID: </span>
                    <Text copyable>{editingVenue.id}</Text>
                </Paragraph>
            ) : undefined}

            <ProFormText
                name="name"
                label="Name"
                placeholder="Venue name"
                rules={[{ required: true }]}
            />
            <ProForm.Item label="Center" required>
                <Space.Compact>
                    <ProFormDigit
                        name="latitude"
                        placeholder="Latitude"
                        rules={[{ required: true }]}
                        max={90}
                        min={-90}
                        fieldProps={{
                            onPaste: (evt) => {
                                const pastedValue = evt.clipboardData.getData('text');
                                const parts = pastedValue.split(',');
                                if (parts.length === 2) {
                                    venueForm.setFieldsValue({
                                        latitude: Number(parts[0]),
                                        longitude: Number(parts[1]),
                                    });
                                    evt.preventDefault();
                                }
                            },
                            onBlur: () => _updateMapCenter(),
                        }}
                    />
                    <ProFormDigit
                        name="longitude"
                        placeholder="Longitude"
                        rules={[{ required: true }]}
                        max={180}
                        min={-180}
                        fieldProps={{
                            onBlur: () => _updateMapCenter(),
                        }}
                    />
                </Space.Compact>
                <LocationPicker
                    ref={mapRef}
                    style={{ width: '100%', height: 200 }}
                    center={isEdit ? editingVenue.center : undefined}
                    onChange={(c) => {
                        venueForm.setFieldsValue({
                            latitude: c.lat,
                            longitude: c.lng,
                        });
                    }}
                />
            </ProForm.Item>
            <ProFormGroup>
                <ProFormSwitch name="enabled" label="Enable" hidden={!isEdit} />
                <ProFormSwitch name="indoor" label="Indoor venue" />
            </ProFormGroup>
        </ModalForm>
    );
};

export const showDeleteVenueDialog = (modal: any, venue: any) => {
    return new Promise((resolve, reject) => {
        modal.confirm({
            title: `Delete ${venue.name}`,
            content: (
                <p>
                    Are you sure to delete <b>{venue.name}</b>?
                </p>
            ),
            okText: 'Delete',
            okType: 'danger',
            onOk: () => {
                return new Promise((res, rej) => {
                    deleteVenue(venue.id)
                        .then((resp) => {
                            res(resp);
                            resolve(resp);
                        })
                        .catch((err) => {
                            reject(err);
                            rej(err);
                        });
                });
            },
            onCancel: () => reject(false),
        });
    });
};

export type CloneProjVenueProp = BaseDialogProps & {
    projId: string;
    venueInfo: { id: string; name: string };
};
export const CloneProjVenueModule: React.FC<CloneProjVenueProp> = (props) => {
    const { user, hasAccess } = useAuth();
    const [cloneVenueForm] = Form.useForm();

    const availableProj = useMemo(() => {
        const myProjects = user?.resources ?? [];
        return myProjects
            .filter((el) => {
                return el.projId !== props.projId && hasAccess(ResourceRole.EDITOR, el.projId);
            })
            .map((el) => ({
                value: el.projId,
                label: el.name ?? el.projId,
            }));
    }, [user, props.projId]);

    return (
        <ModalForm
            form={cloneVenueForm}
            title={'Copy Venue - ' + props.venueInfo.name}
            trigger={props.trigger}
            loading={!user?.resources}
            submitter={{
                searchConfig: {
                    submitText: 'Confirm',
                    resetText: 'Cancel',
                },
            }}
            onOpenChange={(flag) => {
                cloneVenueForm.resetFields();
            }}
            onFinish={async (values) => {
                if (!values.projId) {
                    message.error('Please select a project');
                    return false;
                }
                try {
                    const resp = await cloneVenue(props.venueInfo.id, { projectId: values.projId });
                    if (resp.response?.status === 200 || resp.response?.status === 201) {
                        if (props.onSuccess) props.onSuccess();
                        return true;
                    }
                } catch (ex) {
                    message.error('error');
                    return false;
                }
            }}
        >
            <Text>Select a destination project</Text>
            <ProFormSelect
                name="projId"
                placeholder="Select a destination project."
                className="w100"
                rules={[{ required: true, message: 'Please select' }]}
                options={availableProj ?? []}
            />
        </ModalForm>
    );
};

export type AddVenueFingerprintProp = BaseDialogProps & {
    venueId: string;
};
export const AddVenueFingerprintModule: React.FC<AddVenueFingerprintProp> = (props) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [venueFingerprintForm] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [pendingZipInfo, setPendingZipInfo] = useState<any>(undefined);

    const _renderUploadButton = (icon: any, text: string) => (
        <Button>
            <Space>
                {icon}
                <Text>{text}</Text>
            </Space>
        </Button>
    );

    const beforeUpload = () => {
        return false;
    };

    const handleChange: UploadProps['onChange'] = (info) => {
        // setImageUrl(undefined);
        setIsLoading(true);
        // //showPreview(info);

        if (info.file.type != 'application/zip') {
            setIsLoading(false);
            message.error('Only ZIP file is accepted');
            return;
        }

        if (info.fileList[info.fileList.length - 1].originFileObj) {
            setIsLoading(false);
            setPendingZipInfo(info.fileList[info.fileList.length - 1]);
        }
    };

    return (
        <ModalForm
            form={venueFingerprintForm}
            title={'Upload site package'}
            trigger={props.trigger}
            initialValues={{}}
            submitter={{
                searchConfig: {
                    submitText: 'Confirm',
                    resetText: 'Cancel',
                },
            }}
            loading={formLoading}
            modalProps={{
                onCancel: () => {
                    venueFingerprintForm.resetFields();
                    setPendingZipInfo(undefined);
                },
            }}
            onOpenChange={(isOpen) => {}}
            onFinish={async (values) => {
                if (!pendingZipInfo) {
                    message.error('Please select a ZIP file');
                    return;
                }

                setFormLoading(true);
                let result = false;

                const mData: VenuePackageCreateDto = {
                    venueId: props.venueId,
                    desc: values.desc,
                    fileName: pendingZipInfo.name,
                };

                try {
                    const resp = await createVenuePackage(mData);
                    if (resp.response?.status === 200 || resp.response?.status === 201) {
                        result = true;
                        if (resp.response.data.data && resp.response.data.data.uploadUrl) {
                            try {
                                message.info('Uploading ZIP file');
                                await NetworkManager.putFile(
                                    resp.response.data.data.uploadUrl,
                                    pendingZipInfo.originFileObj as File,
                                );
                                if (props.onSuccess) props.onSuccess();
                                setFormLoading(false);
                                return result;
                            } catch (ex: any) {
                                message.error('upload file error');
                                setFormLoading(false);
                            }
                        } else {
                            message.error('error');
                            setFormLoading(false);
                        }
                    }
                } catch (ex: any) {
                    message.error('error');
                    setFormLoading(false);
                }
            }}
        >
            <ProFormTextArea
                name="desc"
                label="Description"
                placeholder="Enter a short commit message."
                rules={[{ required: true }]}
            />

            <Form.Item label="Site package (*.zip)" required>
                <Upload
                    name="sp-file"
                    accept=".zip,zip,application/zip"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    fileList={[]}
                    onChange={handleChange}
                >
                    {isLoading
                        ? _renderUploadButton(<LoadingOutlined />, 'Loading')
                        : pendingZipInfo
                          ? _renderUploadButton(<FileZipOutlined />, pendingZipInfo.name)
                          : _renderUploadButton(<UploadOutlined />, 'Click to upload')}
                </Upload>
            </Form.Item>
            <br />
        </ModalForm>
    );
};

export type PublishVenueFingerprintProp = BaseDialogProps & {
    fingerprintId: string;
};
export const PublishVenueFingerprintModule: React.FC<PublishVenueFingerprintProp> = (props) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [publishVenueFingerprintForm] = Form.useForm();
    const fingerprintId = props.fingerprintId;

    return (
        <ModalForm
            form={publishVenueFingerprintForm}
            title={'Publish site package'}
            trigger={props.trigger}
            initialValues={{}}
            submitter={{
                searchConfig: {
                    submitText: 'Publish',
                    resetText: 'Cancel',
                },
            }}
            loading={formLoading}
            modalProps={{
                onCancel: () => {
                    publishVenueFingerprintForm.resetFields();
                },
            }}
            onOpenChange={(isOpen) => {}}
            onFinish={async (values) => {
                setFormLoading(true);

                const mData: VenuePackagePublishDto = {
                    id: fingerprintId,
                    desc: values.desc,
                };
                try {
                    const resp = await publishVenuePackage(mData);
                    if (resp.response?.status === 200 || resp.response?.status === 201) {
                        if (props.onSuccess) props.onSuccess();
                        setFormLoading(false);
                        return true;
                    }
                } catch (ex: any) {
                    message.error('error');
                    setFormLoading(false);
                }
            }}
        >
            <ProFormTextArea
                name="desc"
                label="Release note"
                placeholder="Enter a short release note (max 500 characters)."
                fieldProps={{ showCount: true, maxLength: 500 }}
                rules={[
                    { required: true },
                    {
                        type: 'string',
                        max: 500,
                        message: 'release note cannot longer than 500 char',
                    },
                ]}
            />
        </ModalForm>
    );
};
