import React, { useImperativeHandle, useRef, useState } from 'react';
import { Button, Drawer, Flex, Result, Spin } from 'antd';
import { SourcedVenueDto, SourceStatusDto, startTraining } from 'apis/CrowdApi';
import {
    ProFormDateTimePicker,
    ProFormInstance,
    ProFormSelect,
    ProFormText,
    StepsForm,
} from '@ant-design/pro-components';
import { useAuth } from 'providers/AuthProvider';
import { createVenue, getVenue, VenueType } from 'apis/VenueApi';

export type SourceTrainingDrawerRef = {
    open: (data: SourcedVenueDto) => void;
};

export type EmptyProps = {
    ref?: SourceTrainingDrawerRef;
    onClosed?: (sourceId?: string) => void;
};

const SourceTrainingDrawer = React.forwardRef<SourceTrainingDrawerRef, EmptyProps>((props, ref) => {
    const formMapRef = useRef<React.MutableRefObject<ProFormInstance<any> | undefined>[]>([]);
    const dataRef = useRef<SourcedVenueDto>();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [pickedVenueId, setPickedVenueId] = useState<string | undefined>();
    const [reqResult, setReqResult] = useState<SourceStatusDto>();

    const { user } = useAuth();
    const projOptions = (user?.resources ?? []).map((el) => {
        return { value: el.projId, label: el.name };
    });

    useImperativeHandle(ref, () => ({
        open(data: SourcedVenueDto) {
            dataRef.current = data;
            setPickedVenueId(undefined);
            setReqResult(undefined);
            getVenueInfo(data?.source?.venueId, data.id).then((resp) => {
                if (resp.venueId) setPickedVenueId(resp.venueId);
                const formValues = {
                    project: resp,
                    source: { from: data?.source?.lastSourceAt, to: data?.lastEndAt },
                };
                updateFormData(formValues);
            });
            setOpen(true);
            return;
        },
    }));

    const updateFormData = (input: any) => {
        if (!!formMapRef.current) {
            formMapRef?.current?.forEach((instanceRef) => {
                instanceRef?.current?.setFieldsValue(input);
            });
        }
    };

    const getVenueInfo = async (venueId?: string, extId?: string) => {
        try {
            setIsLoading(true);
            const vResp = venueId
                ? await getVenue(venueId, undefined, false)
                : await getVenue('ext', extId, false);
            const venueData = vResp.data;
            return {
                venueName: venueData?.name,
                venueId: venueData?.id,
                projId: venueData?.projectId,
            };
        } catch (e) {
            /** do nothing */
        } finally {
            setIsLoading(false);
        }
        return Promise.resolve({
            venueName: dataRef.current?.name,
            venueId: undefined,
            projId: undefined,
        });
    };

    const createVenueFromData = async (input: any) => {
        if (!input?.project.projId || !input?.project.venueName || !dataRef.current?.center) {
            return Promise.reject();
        }
        try {
            const resp = await createVenue({
                projectId: input?.project.projId,
                name: input?.project.venueName,
                center: dataRef.current!.center,
                extId: dataRef.current?.id,
                type: VenueType.CROWDSOURCE,
            });
            if (resp.data?.id) {
                return Promise.resolve(resp.data.id);
            }
        } catch (e) {
            return Promise.reject();
        }
    };

    const onClose = () => {
        if (props.onClosed) props.onClosed(reqResult?.id);
        setOpen(false);
        setTimeout(() => {
            dataRef.current = undefined;
            formMapRef?.current?.forEach((instanceRef) => {
                instanceRef?.current?.resetFields();
            });
        }, 100);
    };

    return (
        <Drawer title="Crowdsource data training" onClose={onClose} open={open} width="60%">
            <StepsForm
                key={dataRef?.current?.id}
                formMapRef={formMapRef}
                onFinish={async (values) => {
                    onClose();
                }}
                submitter={isLoading || !!reqResult ? false : undefined}
            >
                <StepsForm.StepForm
                    name="project"
                    title="Select Venue"
                    onFinish={async (values) => {
                        if (pickedVenueId) {
                            // has venue exist
                            return true;
                        } else {
                            try {
                                // Create venue
                                const mVenueId = await createVenueFromData(values);
                                setPickedVenueId(mVenueId);
                                return true;
                            } catch (e) {
                                return false;
                            }
                        }
                    }}
                >
                    {isLoading ? (
                        <div style={{ margin: 24 }}>
                            <Spin />
                        </div>
                    ) : (
                        <>
                            {pickedVenueId ? undefined : (
                                <p>Pick a Locaite venue to included this crowd sourced venue.</p>
                            )}
                            <ProFormSelect
                                width="lg"
                                name={['project', 'projId']}
                                label="Project"
                                placeholder="Please select a project"
                                request={async () => projOptions}
                                rules={[{ required: true }]}
                                readonly={!!pickedVenueId}
                            />
                            <ProFormText
                                name={['project', 'venueName']}
                                width="lg"
                                label="Venue name"
                                placeholder="Please enter the venue name"
                                rules={[{ required: true }]}
                                readonly={!!pickedVenueId}
                            />
                            <ProFormText name={['project', 'venueId']} label="Venue ID" readonly>
                                {pickedVenueId ?? '-'}
                            </ProFormText>
                        </>
                    )}
                </StepsForm.StepForm>
                <StepsForm.StepForm
                    name="period"
                    title="Select Range"
                    onFinish={async (values) => {
                        if (!pickedVenueId || !dataRef.current?.id) return false;
                        try {
                            const resp = await startTraining({
                                id: dataRef.current!.id,
                                sourceFrom: values?.source?.from,
                                sourceTo: values?.source?.to,
                            });
                            if (resp.data?.id) {
                                setReqResult(resp.data);
                            }
                        } catch (ex) {
                            setReqResult(undefined);
                            /* do nothing */
                        }
                        return true;
                    }}
                >
                    <p>
                        Select a period you would like to performance training.
                        <br />
                        Noted: If no data select, the oldest / latest record might selected.
                    </p>
                    <ProFormDateTimePicker name={['source', 'from']} label="From (optional)" width="md" />
                    <ProFormDateTimePicker name={['source', 'to']} label="To (optional)" width="md" />
                </StepsForm.StepForm>
                <StepsForm.StepForm name="finish" title="Finish">
                    {reqResult ? (
                        <Result
                            status="success"
                            title="Training request submitted."
                            subTitle={
                                <Flex vertical>
                                    <span>Ref: {reqResult?.id}</span>
                                    <span>
                                        The training process typically takes approximately 6-24
                                        hours to complete. You can view the status of this process
                                        on this page.
                                    </span>
                                </Flex>
                            }
                            extra={<Button onClick={onClose}>Close</Button>}
                        />
                    ) : (
                        <Result
                            status="warning"
                            title="Failed to request training, please try again later."
                        />
                    )}
                </StepsForm.StepForm>
            </StepsForm>
        </Drawer>
    );
});

export default SourceTrainingDrawer;
