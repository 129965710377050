import {
    ModalForm,
    ProFormText,
    ProForm,
    ProFormDigit,
    ProFormSwitch,
    ProFormTextArea,
    ProFormSelect,
    ProFormGroup,
} from '@ant-design/pro-components';
import { Descriptions, Flex, Form, Space, Typography } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import { BaseDialogProps } from './BaseDialogProps.type';
import { createLandmark, LandmarkDto, updateLandmark } from 'apis/LandmarkApi';
import LocationPicker from 'components/maps/LocationPicker';

export type LandmarkInputProp = BaseDialogProps & {
    venueId: string;
    record?: LandmarkDto;
    availableClusters?: string[];
};

const LandmarkInputModule: React.FC<LandmarkInputProp> = (props) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [inputForm] = Form.useForm();
    const mapRef = useRef<any>();
    const isEdit = useMemo(() => props.record?.id !== undefined, [props.record]);

    const updateMapContent = () => {
        mapRef.current.flyTo({
            lat: inputForm.getFieldValue('latitude'),
            lng: inputForm.getFieldValue('longitude'),
        });
        mapRef.current.updateRadius(inputForm.getFieldValue('radius'));
    };

    const _renderFormBody = () => {
        return (
            <>
                {isEdit ? (
                    <Descriptions column={1} style={{ marginBottom: 12 }}>
                        <Descriptions.Item label="ID">
                            <Typography.Text copyable>{props.record!.id}</Typography.Text>
                        </Descriptions.Item>
                    </Descriptions>
                ) : undefined}

                <ProFormText
                    name="extId"
                    label="Ext. ID"
                    placeholder="(Optional) Enter external ID"
                    rules={[{ required: false }]}
                />

                <ProFormText
                    name="name"
                    label="Name"
                    placeholder="Enter landmark name"
                    rules={[{ required: true }]}
                />

                <ProFormGroup>
                    <ProForm.Item label="Coordinate" required>
                        <Space.Compact>
                            <ProFormDigit
                                name="latitude"
                                placeholder="Latitude"
                                rules={[{ required: true }]}
                                max={90}
                                min={-90}
                                fieldProps={{
                                    onPaste: (evt) => {
                                        const pastedValue = evt.clipboardData.getData('text');
                                        const parts = pastedValue.split(',');
                                        if (parts.length === 2) {
                                            inputForm.setFieldsValue({
                                                latitude: Number(parts[0]),
                                                longitude: Number(parts[1]),
                                            });
                                            evt.preventDefault();
                                        }
                                    },
                                    onBlur: () => updateMapContent(),
                                }}
                                noStyle
                            />
                            <ProFormDigit
                                name="longitude"
                                placeholder="Longitude"
                                rules={[{ required: true }]}
                                max={180}
                                min={-180}
                                noStyle
                                fieldProps={{ onBlur: () => updateMapContent() }}
                            />
                        </Space.Compact>
                    </ProForm.Item>
                    <ProFormDigit
                        name="radius"
                        label="Radius"
                        placeholder="Buffer radius in meters"
                        min={1}
                        rules={[{ required: true }]}
                        fieldProps={{ onBlur: () => updateMapContent() }}
                    />
                </ProFormGroup>

                <ProFormTextArea
                    name="address"
                    label="Address"
                    placeholder="Enter address"
                    fieldProps={{ rows: 2 }}
                />

                <ProForm.Group>
                    <ProFormSwitch name="isIndoor" label="Indoor" rules={[{ required: true }]} />
                    <ProFormDigit name="level" label="Level" rules={[{ required: true }]} />
                </ProForm.Group>

                <ProFormSelect
                    name="clusters"
                    label="Clusters"
                    mode="tags"
                    fieldProps={{ tokenSeparators: [',', ' '] }}
                    options={(props.availableClusters ?? []).map((el) => ({
                        value: el,
                        label: el,
                    }))}
                />
            </>
        );
    };

    return (
        <ModalForm
            form={inputForm}
            title={isEdit ? 'Edit landmark' : 'Add landmark'}
            trigger={props.trigger}
            modalProps={{ style: { top: 24, zIndex: 1210 }}}
            className="tiny-row"
            width="90%"
            initialValues={{
                radius: 3,
                isIndoor: false,
                level: 0,
                clusters: [],
            }}
            submitter={{
                searchConfig: {
                    submitText: isEdit ? 'Update' : 'Create',
                    resetText: 'Cancel',
                },
            }}
            loading={formLoading}
            onOpenChange={(isOpen) => {
                if (isOpen) {
                    inputForm.setFieldsValue({
                        name: props.record?.name,
                        extId: props.record?.extId,
                        address: props.record?.address,
                        latitude: props.record?.position?.lat,
                        longitude: props.record?.position?.lng,
                        radius: props.record?.radius,
                        isIndoor: props.record?.isIndoor ?? false,
                        level: props.record?.level ?? 0,
                        clusters: props.record?.clusters ?? [],
                    });
                    setTimeout(() => {
                        if (mapRef) mapRef.current.updateRadius(props.record?.radius);
                    }, 500);
                } else {
                    inputForm.resetFields();
                }
            }}
            onFinish={async (values) => {
                setFormLoading(true);
                const mDto: any = {
                    venueId: props.venueId,
                    name: values.name,
                    extId: values.extId,
                    address: values.address,
                    position: { lat: values.latitude, lng: values.longitude },
                    radius: values.radius ?? 3,
                    clusters: values.clusters ?? [],
                    level: values.level ?? 0,
                    isIndoor: values.isIndoor ?? false,
                };
                try {
                    if (isEdit) mDto['id'] = props.record!.id;
                    const resp = isEdit
                        ? await updateLandmark(props.record!.id, mDto)
                        : await createLandmark(mDto);
                    if (props.onSuccess) props.onSuccess(resp.data);
                    setFormLoading(false);
                    return true;
                } catch (ex) {
                    setFormLoading(false);
                    if (props.onSuccess) props.onSuccess(false);
                    return false;
                }
            }}
        >
            <Flex>
                <Flex flex={3} vertical>
                    {_renderFormBody()}
                </Flex>
                <Flex flex={2} style={{ marginLeft: 16, display: 'flex' }}>
                    <LocationPicker
                        key={props.record?.id ?? new Date().getTime()}
                        ref={mapRef}
                        style={{ width: '100%', height: '100%', position: 'relative' }}
                        center={isEdit ? props.record?.position : undefined}
                        onChange={(c) => {
                            inputForm.setFieldsValue({
                                latitude: c.lat,
                                longitude: c.lng,
                            });
                        }}
                    />
                </Flex>
            </Flex>
        </ModalForm>
    );
};
export default LandmarkInputModule;
