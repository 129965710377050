import ApiClient from 'utils/NetworkManager';

export interface DataDto {
    data: string;
}

export interface LoginDto {
    email: string;
    password: string;
}

export interface LoginResultDto {
    uid: string;
    accessToken: string;
    expiresTs: number;
}

export const getPublicKey = () => ApiClient.get<DataDto>(`/auth/pk`, null, { handleException: false });
export const signin = (data: LoginDto) => ApiClient.e2eePost<LoginResultDto>(`/auth/login`, data);
export const signout = () => ApiClient.post<DataDto>(`/auth/logout`);