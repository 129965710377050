import { PageContainer, ProCard, ProList } from '@ant-design/pro-components';
import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, Tag, Typography, Flex, Button, message, Space, Input } from 'antd';
import { ProjectInfoDto, getProjectList } from 'apis/ProjectApi';
import { useAppState } from 'providers/AppStateProvider';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ProjectInputModal } from 'components/dialogs/InputProjectDialog';
import { useAuth } from 'providers/AuthProvider';
import { ResourceRole } from 'apis/UserApi';
const { Text, Title } = Typography;

const HomeScreen: React.FC = () => {
    const { refreshUser, isSuperAdmin, hasAccess } = useAuth();
    const { activeProject, switchProject, isMobile, appSettings } = useAppState();
    const [projectList, setProjectList] = useState<ProjectInfoDto[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [keyword, setKeyword] = useState<string | undefined>();
    const navigate = useNavigate();

    useEffect(() => {
        _refreshProjList();
    }, []);

    const _refreshProjList = () => {
        setIsLoading(true);
        getProjectList()
            .then((resp) => {
                setProjectList((resp.data ?? []).sort((a, b) => a.name.localeCompare(b.name)));
            })
            .catch(() => {
                setProjectList([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const _handleClickProj = (id: string) => {
        switchProject(id);
        if (hasAccess(ResourceRole.VIEWER, id)) {
            navigate('/project/');
        }
    };

    const recentData = useMemo(() => {
        if (projectList.length === 0) return [];
        const recentProjIds = (appSettings.recentProj ?? []).reverse();
        const result = [];
        for (let i = 0; i < recentProjIds.length; i++) {
            const item = (projectList ?? []).find((el) => el.id === recentProjIds[i]);
            if (!!item) {
                result.push({
                    _item: item,
                    title: (
                        <Space>
                            <Avatar shape="square">
                                {(item.name ?? '-').toUpperCase().charAt(0)}
                            </Avatar>
                            <span className="single-line-ellipsis">{item.name ?? '-'}</span>
                        </Space>
                    ),
                    subTitle:
                        activeProject?.projId === item.id ? (
                            <Tag color="#8BC34A">Active</Tag>
                        ) : undefined,
                    [isMobile ? 'description' : 'content']: (
                        <Flex justify="space-between" style={{ width: '100%' }}>
                            <Text type="secondary" ellipsis>
                                {item.description}
                            </Text>
                        </Flex>
                    ),
                });
            }
        }
        return result;
    }, [projectList, isMobile]);

    const filteredProjects = useMemo(() => {
        if (!keyword) return projectList ?? [];
        return (projectList ?? []).filter((el) => {
            return el.name.toLowerCase().includes(keyword) || el.id.toLowerCase().includes(keyword);
        });
    }, [projectList, keyword]);

    const _renderRecentProj = () => {
        return (
            <ProCard
                ghost
                title={
                    <Title level={4} className="no-margin">
                        Recent Projects
                    </Title>
                }
                headStyle={{ padding: 0 }}
                bodyStyle={{ padding: 0 }}
            >
                <ProList<any>
                    ghost={true}
                    showActions="hover"
                    grid={
                        isMobile
                            ? undefined
                            : {
                                  gutter: 16,
                                  xs: 1,
                                  sm: 2,
                                  md: 3,
                                  lg: 4,
                                  xl: 4,
                                  xxl: 6,
                              }
                    }
                    onItem={(record: any) => ({ onClick: () => _handleClickProj(record._item.id) })}
                    metas={{ title: {}, description: {}, subTitle: {}, content: {} }}
                    itemCardProps={{ style: { marginBlockEnd: 0, width: '100%' } }}
                    dataSource={recentData}
                />
            </ProCard>
        );
    };

    return (
        <PageContainer title={false}>
            {recentData.length > 0 ? _renderRecentProj() : undefined}
            <ProCard
                ghost
                title={
                    <Title level={4} className="no-margin">
                        My Projects
                    </Title>
                }
                subTitle={
                    isMobile ? undefined : (
                        <Space>
                            <Input
                                key="proj-search-box"
                                size="small"
                                allowClear
                                variant="filled"
                                style={{ maxWidth: 180 }}
                                suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,0.3)' }} />}
                                onChange={(e) => {
                                    setKeyword(e.target?.value?.toLowerCase());
                                }}
                                placeholder="Search a project"
                            />
                            {keyword ? (
                                <span className="text-08">
                                    {filteredProjects.length}/{projectList.length} matches
                                </span>
                            ) : undefined}
                        </Space>
                    )
                }
                style={{ marginTop: 20 }}
                headStyle={{ paddingTop: 0 }}
                bodyStyle={{ padding: 0 }}
                extra={[
                    isSuperAdmin ? (
                        <ProjectInputModal
                            key="btn-new-proj"
                            mode="ACTION_CREATE"
                            trigger={
                                <Button type="primary" icon={<PlusOutlined />}>
                                    New project
                                </Button>
                            }
                            onSuccess={() => {
                                refreshUser();
                                _refreshProjList();
                                message.success('Project created');
                            }}
                        />
                    ) : undefined,
                ]}
            >
                <ProList<any>
                    rowKey="id"
                    size="small"
                    showActions="hover"
                    rowClassName="cursor-pointer"
                    loading={isLoading}
                    onItem={(record: any) => ({ onClick: () => _handleClickProj(record.id) })}
                    metas={{
                        avatar: {
                            render: (dom, entity, index) => (
                                <Avatar shape="square">
                                    {(entity.name ?? '-').toUpperCase().charAt(0)}
                                </Avatar>
                            ),
                        },
                        title: { dataIndex: 'name' },
                        description: {
                            dataIndex: 'description',
                            render: (dom, entity) => {
                                return (
                                    <Typography.Paragraph
                                        ellipsis={{ rows: 2 }}
                                        type="secondary"
                                        style={{ marginBottom: 0 }}
                                    >
                                        {entity.description}
                                    </Typography.Paragraph>
                                );
                            },
                        },
                        subTitle: {
                            render: (dom, entity, index) =>
                                activeProject?.projId === entity.id ? (
                                    <Tag color="#8BC34A">Active</Tag>
                                ) : undefined,
                        },
                        content: {},
                    }}
                    dataSource={filteredProjects}
                />
            </ProCard>
        </PageContainer>
    );
};

export default HomeScreen;
