import { PageContainer, ProDescriptions, ProCard } from '@ant-design/pro-components';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, message, Typography, Tooltip, Tag, Space, Menu, Flex } from 'antd';
import { EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { BoundConfigModule, VenuePackageConfigModule } from 'components/dialogs/ConfigInputDialog';
import { useAuth } from 'providers/AuthProvider';
import { useVenueState } from 'providers/VenueProvider';
import moment from 'moment';
import { formatDateAgo } from 'utils/DateUtils';
import VenueBoundMapView from 'components/maps/VenueBoundMapView';
import TextArea from 'antd/es/input/TextArea';
import { useAppState } from 'providers/AppStateProvider';
import { ViewDocumentModule } from 'components/dialogs/DocumentDialog';
import RuntimeConfigSection from './RuntimeConfigSection';
import {
    getVenueActiveBoundConfig,
    getVenueRuntimeConfig,
    VenueBoundConfigDto,
    VenueConfigGroup,
    VenueDetailDto,
    VenueInfoDto,
} from 'apis/VenueApi';
import { useOutdoorState } from 'providers/OutdoorProvider';
const { Text } = Typography;

interface IVenueIdProps {
    venue?: VenueInfoDto | VenueDetailDto;
}

const BoundConfigSection: React.FC<IVenueIdProps> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [boundConf, setBoundConf] = useState<VenueBoundConfigDto>();
    const [messageApi, contextHolder] = message.useMessage();

    const fetchBoundConfig = () => {
        if (!props?.venue?.id) return;
        setIsLoading(true);
        getVenueActiveBoundConfig(props.venue!.id)
            .then((res) => setBoundConf(res.data))
            .catch(() => setBoundConf(undefined))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (!!props.venue?.id) fetchBoundConfig();
    }, [props.venue]);

    if (!props.venue) return <span>Venue is not available</span>;

    return (
        <ProCard
            title={'Bound config'}
            tooltip="Venue detection criteria"
            loading={isLoading}
            extra={
                !boundConf ? undefined : (
                    <BoundConfigModule
                        venueId={props.venue.id}
                        config={boundConf}
                        trigger={
                            <Button icon={<EditOutlined />} title="Edit">
                                Edit
                            </Button>
                        }
                        onSuccess={() => {
                            fetchBoundConfig();
                            messageApi.success('Bound config updated');
                        }}
                    />
                )
            }
        >
            {contextHolder}
            {!boundConf ? undefined : (
                <ProDescriptions column={2} layout="vertical">
                    <ProDescriptions.Item label="Doorway beacons">
                        {boundConf.beacons ? boundConf.beacons.length : 0}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="Radius">{boundConf.radius}m</ProDescriptions.Item>
                    <ProDescriptions.Item label="Boundary">
                        <div style={{ width: '100%', height: 300 }}>
                            <VenueBoundMapView
                                center={props.venue.center}
                                radius={boundConf.radius}
                                polygon={boundConf.polygon}
                            />
                        </div>
                    </ProDescriptions.Item>
                </ProDescriptions>
            )}
        </ProCard>
    );
};

const PackageConfigSection: React.FC<IVenueIdProps> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [rtConfig, setRtConfig] = useState<VenueConfigGroup>();
    const [messageApi, contextHolder] = message.useMessage();

    function fetchVenueRtConfig() {
        if (!props.venue?.id) return;
        setIsLoading(true);
        getVenueRuntimeConfig(props.venue.id)
            .then(async (resp) => setRtConfig(resp.data))
            .catch(() => setRtConfig(undefined))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (!!props.venue?.id) fetchVenueRtConfig();
    }, [props.venue]);

    if (!props.venue) return <span>Venue is not available</span>;

    return (
        <ProCard
            title="Package config"
            loading={isLoading}
            tooltip={{
                icon: (
                    <ViewDocumentModule
                        trigger={<QuestionCircleOutlined />}
                        docName="packageConfig.md"
                    />
                ),
            }}
            extra={[
                <Space key="pkg-ext-tag">
                    {rtConfig?.package?.enabled === true ? undefined : (
                        <Tag style={{ fontSize: '110%', padding: 5 }} color="gold">
                            Using default config
                        </Tag>
                    )}
                </Space>,
                <VenuePackageConfigModule
                    key="pkg-ext-edit"
                    venueId={props.venue.id}
                    config={rtConfig?.package}
                    trigger={
                        <Button icon={<EditOutlined />} title="Edit">
                            Edit
                        </Button>
                    }
                    onSuccess={() => {
                        fetchVenueRtConfig();
                        messageApi.success('Package config saved');
                    }}
                />,
            ]}
        >
            {contextHolder}
            <Flex>
                <Flex gap={'1em'} style={{ flexDirection: 'column' }}>
                    <Flex gap={'1em'}>
                        {rtConfig?.package?.desc ?? '-'}
                        <Tag color={rtConfig?.package?.enabled ? 'green' : 'lightgrey'}>
                            {rtConfig?.package?.enabled ? 'ENABLED' : 'DISABLED'}
                        </Tag>
                    </Flex>
                    <Flex gap={'1em'}>
                        <Text>Last update: </Text>
                        {rtConfig?.package?.updatedAt ? (
                            <Tooltip
                                title={moment(new Date(rtConfig?.package.updatedAt)).format(
                                    'DD-MMMM-YYYY HH:mm',
                                )}
                            >
                                {formatDateAgo(rtConfig?.package.updatedAt)}
                            </Tooltip>
                        ) : (
                            <span>-</span>
                        )}
                    </Flex>
                    {rtConfig?.package?.content ? (
                        <TextArea
                            rows={5}
                            contentEditable={false}
                            readOnly={true}
                            value={JSON.stringify(JSON.parse(rtConfig?.package?.content), null, 3)}
                        />
                    ) : undefined}
                </Flex>
            </Flex>
        </ProCard>
    );
};

const VenueConfigScreen: React.FC<{ outdoor?: boolean }> = (props) => {
    const { isSuperAdmin } = useAuth();
    const { isMobile } = useAppState();
    const { venue } = props.outdoor ? useOutdoorState() : useVenueState();
    const [configTab, setConfigTab] = useState<string>('runtime');
    if (!isSuperAdmin) return <span>No permission.</span>;

    const menuItems = useMemo(() => {
        return props.outdoor
            ? [{ key: 'runtime', label: 'Runtime' }]
            : [
                  { key: 'runtime', label: 'Runtime' },
                  { key: 'bound', label: 'Bound' },
                  { key: 'package', label: 'Package' },
              ];
    }, [props.outdoor]);

    return (
        <PageContainer breadcrumb={undefined} title={null} header={{ title: undefined }}>
            <ProCard split={isMobile ? 'horizontal' : 'vertical'}>
                <Menu
                    onClick={(e) => setConfigTab(e.key)}
                    style={
                        isMobile
                            ? { backgroundColor: 'transparent' }
                            : {
                                  width: '20%',
                                  maxWidth: '200px',
                                  padding: '1em',
                                  backgroundColor: 'transparent',
                              }
                    }
                    defaultSelectedKeys={['runtime']}
                    mode={isMobile ? 'horizontal' : 'inline'}
                    items={menuItems}
                />
                <ProCard ghost>
                    {configTab === 'runtime' ? (
                        <RuntimeConfigSection parentId={venue?.id} enableSimulate />
                    ) : configTab === 'bound' ? (
                        <BoundConfigSection venue={venue} />
                    ) : configTab === 'package' ? (
                        <PackageConfigSection venue={venue} />
                    ) : undefined}
                </ProCard>
            </ProCard>
        </PageContainer>
    );
};

export default VenueConfigScreen;
