import { PageContainer, ProCard } from '@ant-design/pro-components';
import React, { useEffect, useState } from 'react';
import { useVenueState } from 'providers/VenueProvider';
import moment from 'moment';
import { ActivityLogDto, getActivityLog } from 'apis/VenueApi';
import { TableProps } from 'antd/lib';
import Markdown from 'react-markdown';
import { Space, Table } from 'antd';
import { useOutdoorState } from 'providers/OutdoorProvider';

const ActivityLogScreen: React.FC<{ outdoor?: boolean }> = (props) => {
    const { venue } = props.outdoor ? useOutdoorState() : useVenueState();
    const venueId = venue?.id;
    const [logData, setLogData] = useState<ActivityLogDto[]>([]);
    const [pageConfig, setPageConfig] = useState<any>({ total: 0, totalPage: 0 });
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!!venueId) _fetchLog(0);
    }, [venueId]);

    function _fetchLog(page: number, size?: number) {
        if (!venueId) return;
        setLoading(true);
        getActivityLog(venueId!, page, size)
            .then((resp) => {
                setLogData(resp.data ?? []);
                setPageConfig(resp.response?.data._metadata?.pagination);
            })
            .finally(() => {
                setLoading(false);
            });
    }
    const columns: TableProps<ActivityLogDto>['columns'] = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (field, entity, index) => {
                return moment(entity.date).format('DD-MMMM-YYYY HH:mm');
            },
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            key: 'detail',
            render: (field, entity, index) => (
                <Markdown className="p-no-margin">{entity.detail}</Markdown>
            ),
        },
    ];

    return (
        <PageContainer breadcrumb={undefined} title={null} header={{ title: undefined }}>
            {logData.length > 0 ? (
                <Space style={{ margin: '8px 0' }}>
                    Period:
                    <span>
                        {moment(logData[logData.length - 1].date).format('DD-MMMM-YYYY HH:mm')}
                    </span>
                    to
                    <span>{moment(logData[0].date).format('DD-MMMM-YYYY HH:mm')}</span>
                </Space>
            ) : undefined}
            <ProCard bodyStyle={{ padding: 12 }}>
                <Table
                    loading={loading}
                    rowKey={(record) => `${record['date']}-${Math.random()}`}
                    columns={columns}
                    dataSource={logData}
                    pagination={{
                        defaultCurrent: 1,
                        defaultPageSize: 20,
                        total: pageConfig?.total ?? 1,
                        onChange: (page, size) => {
                            _fetchLog(page - 1, size);
                        },
                    }}
                    size="small"
                />
            </ProCard>
        </PageContainer>
    );
};

export default ActivityLogScreen;
