import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import { githubLight } from '@uiw/codemirror-theme-github';
import { EditorView } from 'codemirror';

export type CodeEditorRef = {
    setValue: (value: string) => void;
    getValue: () => string;
};

interface CodeEditorProps {
    value?: string;
    readonly?: boolean;
    height?: 'auto' | number;
    className?: string;
    placeholder?: string;
    onChange?: (value: string) => void;
    lineWrapping?: boolean;
}

const CodeEditor = React.forwardRef<CodeEditorRef, CodeEditorProps>((props, ref) => {
    const [value, setValue] = useState(props.value ?? '');
    const onChange = useCallback((value: string, viewUpdate: any) => {
        if (props.onChange) props.onChange(value);
        setValue(value);
    }, []);

    const conf = useMemo(() => {
        const extensions = [
            json(),
            EditorView.editable.of(!(props?.readonly ?? false)),
        ];
        if (props.lineWrapping) extensions.push(EditorView.lineWrapping);
        return {
            className: props.className,
            onChange: onChange,
            height: typeof props.height === 'number' ? `${props.height}px` : props.height,
            theme: githubLight,
            extensions: extensions,
            placeholder: props.placeholder,
        };
    }, []);

    useImperativeHandle(ref, () => ({
        setValue: (value: string) => setValue(value),
        getValue: () => value,
    }));

    return <CodeMirror value={value} {...conf} />;
});
export default React.memo(CodeEditor, () => true);
