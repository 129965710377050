import React, { useMemo, useRef } from 'react';
import type { ProSettings } from '@ant-design/pro-components';
import { ProConfigProvider, ProLayout } from '@ant-design/pro-components';
import {
    LogoutOutlined,
    KeyOutlined,
    DownOutlined,
    ReadOutlined,
    QuestionCircleOutlined,
    RightOutlined,
} from '@ant-design/icons';
import { Button, ConfigProvider, Dropdown, Flex, Space, Typography } from 'antd';
import { useAuth } from 'providers/AuthProvider';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getPrivateRoute } from 'router/PrivateRouteConfig';
import AliasAvatar from './AliasAvatar';
import { useAppState } from 'providers/AppStateProvider';
import en_US from 'antd/es/locale/en_US';
import { ResourceRole } from 'apis/UserApi';
import LocaiteLightLogo from 'assets/locaite_logo_light.png';
import ChangePasswordModule from './dialogs/ChangePasswordDialog';
import type { AvatarProps } from 'antd';
import { ViewDocumentModule } from './dialogs/DocumentDialog';

const layoutSettings: Partial<ProSettings> = {
    fixSiderbar: true,
    layout: 'mix',
    splitMenus: false,
    navTheme: 'light',
    contentWidth: 'Fluid',
    fixedHeader: false,
    siderMenuType: 'sub',
};

export const ScreenLayout = () => {
    const changePwdRef = useRef<any>();
    const location = useLocation();
    const { isAuthorized, user, logout, isSuperAdmin, hasAccess, setPostLoginPath } = useAuth();
    if (!isAuthorized) {
        setPostLoginPath(location.pathname + location.search);
        return <Navigate to="/login" />;
    }
    const { activeProject, switchProject, isMobile, navCollapsed, setNavCollapsed } = useAppState();
    const navigate = useNavigate();

    const privateRoute = useMemo(() => {
        const isEditor = hasAccess(ResourceRole.EDITOR, activeProject?.projId);
        return getPrivateRoute(activeProject, isSuperAdmin, isEditor);
    }, [activeProject, user]);

    const projectSelector = useMemo(() => {
        let displayLabel = 'Select a project';
        const mItems = (user?.resources ?? []).map((el) => {
            if (activeProject?.projId === el.projId) displayLabel = el.name ?? '-';
            return { key: el.projId, label: el.name };
        });
        return [
            <Dropdown.Button
                key="header-proj-dd"
                size="small"
                icon={<DownOutlined />}
                menu={{
                    items: mItems,
                    selectable: true,
                    defaultSelectedKeys: activeProject?.projId ? [activeProject!.projId] : [],
                    onClick: ({ key }) => {
                        switchProject(key);
                        navigate('/project/');
                    },
                }}
                onClick={() => {
                    if (!!activeProject?.projId) navigate('/project/');
                }}
            >
                <Typography.Text style={isMobile ? { width: 100 } : undefined} ellipsis>
                    {displayLabel}
                </Typography.Text>
            </Dropdown.Button>,
        ];
    }, [user, activeProject]);

    const avatarConf = useMemo<AvatarProps>(() => {
        return {
            src: <AliasAvatar size="small" name={user?.name ?? ''} />,
            size: 'small',
            title: user?.name,
            render: (aProps: any, aDom: any) => (
                <>
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    key: 'ChangePwd',
                                    icon: <KeyOutlined />,
                                    label: 'Change password',
                                    onClick: () => {
                                        if (!!changePwdRef.current) {
                                            changePwdRef.current.open();
                                        }
                                    },
                                },
                                { type: 'divider' },
                                {
                                    key: 'logout',
                                    icon: <LogoutOutlined />,
                                    label: 'Logout',
                                    onClick: logout,
                                },
                            ],
                        }}
                    >
                        {aDom}
                    </Dropdown>
                    <ChangePasswordModule ref={changePwdRef} />
                </>
            ),
        };
    }, [user]);

    return (
        <div id="main-layout" style={{ height: '100vh' }}>
            <ConfigProvider
                locale={en_US}
                getTargetContainer={() => {
                    return document.getElementById('main-layout') || document.body;
                }}
                theme={{
                    components: {
                        Descriptions: {
                            itemPaddingBottom: 8,
                        },
                        Alert: {
                            withDescriptionPadding: '12px',
                        },
                        Tree: {
                            nodeSelectedBg: '#d3e2fd',
                        },
                    },
                }}
            >
                <ProConfigProvider hashed={true}>
                    <ProLayout
                        token={{
                            header: {
                                heightLayoutHeader: 40,
                            },
                            pageContainer: {
                                paddingBlockPageContainerContent: 16,
                                paddingInlinePageContainerContent: 24,
                            },
                        }}
                        logo={
                            <img
                                src={LocaiteLightLogo}
                                alt="Locaite Portal"
                                title="Locaite Portal"
                                className="cursor-pointer"
                                onClick={() => navigate('/home')}
                            />
                        }
                        title={'Locaite Portal'}
                        headerTitleRender={(logo, title) => <>{logo}</>}
                        locale="en-US"
                        location={{ pathname: location.pathname }}
                        onPageChange={(pageLocation) => {
                            if (
                                !!pageLocation?.pathname &&
                                pageLocation.pathname !== location.pathname
                            ) {
                                navigate(pageLocation.pathname);
                            }
                        }}
                        contentStyle={{ padding: 0 }}
                        siderMenuType="sub"
                        collapsed={navCollapsed}
                        onCollapse={setNavCollapsed}
                        menu={{ collapsedShowGroupTitle: true }}
                        menuProps={{ onClick: (e) => navigate(e.key || '/home') }}
                        menuExtraRender={(props) => {
                            if (props.isMobile) return projectSelector;
                            return undefined;
                        }}
                        menuFooterRender={(props) => {
                            if (props?.collapsed) return undefined;
                            return <SideMenuFooter />;
                        }}
                        avatarProps={avatarConf}
                        actionsRender={(props) => {
                            if (props.isMobile || typeof window === 'undefined') return [];
                            return projectSelector;
                        }}
                        {...layoutSettings}
                        route={{
                            path: '/',
                            routes: privateRoute,
                            history: { type: 'browser' },
                        }}
                    >
                        <Outlet />
                    </ProLayout>
                </ProConfigProvider>
            </ConfigProvider>
        </div>
    );
};

const SideMenuFooter = React.memo((props) => {
    const _renderBtnRow = (icon: any, text: any) => (
        <Flex align="center" justify="space-between" className="w100" style={{ color: '#aaa' }}>
            <Space>
                {icon} <span>{text}</span>
            </Space>
            <RightOutlined className="text-08" />
        </Flex>
    );
    return (
        <div
            style={{
                textAlign: 'center',
                paddingBlockStart: 12,
                borderTop: '#ddd 0.5px solid',
                color: '#aaa',
            }}
        >
            <ViewDocumentModule
                btnStyle={{ width: '100%' }}
                trigger={_renderBtnRow(<QuestionCircleOutlined />, 'Get started')}
                docName="integration.md"
            />
            <Button
                className="w100"
                type="text"
                size="small"
                href="https://docs.locaite.com"
                target="_blank"
            >
                {_renderBtnRow(<ReadOutlined />, 'API Docs')}
            </Button>
            <div style={{ marginTop: 6 }}>©2024 by Locaite</div>
        </div>
    );
});
