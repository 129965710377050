import { ProCard } from '@ant-design/pro-components';
import React, { useMemo } from 'react';
import { Space, Button, Tag, Typography, Flex, Modal, message, Divider } from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    CloseOutlined,
    EnvironmentOutlined,
    AimOutlined,
    TagsOutlined,
    HomeOutlined,
    PoweroffOutlined,
} from '@ant-design/icons';
import { useAuth } from 'providers/AuthProvider';
import { useAppState } from 'providers/AppStateProvider';
import { ResourceRole } from 'apis/UserApi';
import { useOutdoorState } from 'providers/OutdoorProvider';
import LandmarkInputDialog from 'components/dialogs/LandmarkInputDialog';
import { deleteLandmark } from 'apis/LandmarkApi';
import LandmarkSurveySection from './LandmarkSurveySection';
import LandmarkStatsSection from './LandmarkStatsSection';
import moment from 'moment';
const { Text, Title } = Typography;

const LandmarkDetailSection: React.FC = () => {
    const { workingLandmark, availableClusters, fetchLandmarks, selectLandmark } =
        useOutdoorState();
    const { hasAccess } = useAuth();
    const { activeProject } = useAppState();
    const isEditor = hasAccess(ResourceRole.EDITOR, activeProject?.projId);
    const [modal, contextHolder] = Modal.useModal();
    const [messageApi, messageCtxHolder] = message.useMessage();

    const _handleDelete = () => {
        if (!workingLandmark) return;
        modal
            .confirm({
                title: 'Delete landmark',
                content: (
                    <span>
                        Are you sure to delete <b>{workingLandmark.name}</b>?
                    </span>
                ),
                okText: 'Delete',
                okType: 'danger',
            })
            .then(
                (confirmed) => {
                    if (confirmed) {
                        deleteLandmark(workingLandmark.id)
                            .then((resp) => {
                                selectLandmark(undefined);
                                fetchLandmarks();
                                messageApi.info('Landmark removed');
                            })
                            .catch(() => {});
                    }
                },
                () => {},
            );
    };

    const tbDataSource = useMemo(() => {
        if (!workingLandmark) return [];
        const mRows = [
            [<EnvironmentOutlined title="Address" />, workingLandmark.address ?? '-'],
            [
                <AimOutlined title="Coordinate" />,
                <Space size="large">
                    <span>
                        <Text copyable>
                            {workingLandmark.position.lat}, {workingLandmark.position.lng}
                        </Text>
                    </span>
                    <Space>
                        <PoweroffOutlined className="grey-icon" rotate={90} title="Radius" />
                        {workingLandmark.radius}m
                    </Space>
                </Space>,
            ],
            [
                <TagsOutlined title="Clusters" />,
                <Flex wrap="wrap">
                    {(workingLandmark.clusters ?? []).map((c, i) => {
                        return (
                            <Tag
                                className="rounded-tag cursor-pointer"
                                key={`clusters-${i}-${c}`}
                                onClick={() => fetchLandmarks({ tags: [c] })}
                            >
                                {c}
                            </Tag>
                        );
                    })}
                </Flex>,
            ],
            [
                <HomeOutlined title="Floor" />,
                <Space>
                    <span>{workingLandmark.level}</span>
                    {workingLandmark.isIndoor ? (
                        <Tag color="cyan" bordered={false}>
                            Indoor
                        </Tag>
                    ) : undefined}
                </Space>,
            ],
        ];
        if (isEditor) {
            mRows.push([
                <EditOutlined title="Settings" />,
                <Flex vertical>
                    <Text type="secondary" className="text-08">
                        Last update:{' '}
                        {moment(workingLandmark.updatedAt).format('YYYY-MM-DD HH:mm Z')}
                    </Text>
                    <Space>
                        <Button
                            icon={<DeleteOutlined />}
                            size="small"
                            variant="dashed"
                            onClick={_handleDelete}
                        >
                            Delete
                        </Button>

                        <LandmarkInputDialog
                            key="btn-edit-lm"
                            venueId={workingLandmark.venueId}
                            record={workingLandmark}
                            availableClusters={availableClusters}
                            trigger={
                                <Button variant="dashed" icon={<EditOutlined />} size="small">
                                    Edit
                                </Button>
                            }
                            onSuccess={() => {
                                fetchLandmarks();
                                messageApi.success('Landmark updated');
                            }}
                        />
                    </Space>
                </Flex>,
            ]);
        }
        return mRows;
    }, [workingLandmark]);

    const cardPadding = useMemo(() => ({ padding: 12, margin: 0 }), []);
    if (!workingLandmark) return <></>;
    return (
        <ProCard
            colSpan="30%"
            style={{ overflowY: 'auto' }}
            bodyStyle={{ padding: 0, display: 'flex', flexDirection: 'column' }}
            bordered
        >
            {contextHolder}
            {messageCtxHolder}
            <div style={{ ...cardPadding, backgroundColor: '#e6f4ffaa' }}>
                <Flex justify="space-between" align="center">
                    <Title level={5} style={{ margin: 0 }}>
                        {workingLandmark.name}
                    </Title>
                    <Button
                        type="text"
                        size="small"
                        icon={<CloseOutlined style={{ opacity: 0.5 }} />}
                        onClick={() => {
                            selectLandmark(undefined);
                        }}
                    />
                </Flex>
                <Flex vertical>
                    <Text className="text-08" type="secondary" copyable>
                        {workingLandmark.id}
                    </Text>
                    {workingLandmark.extId ? (
                        <Text
                            className="text-08"
                            type="secondary"
                            copyable={{ text: workingLandmark.extId }}
                        >
                            ({workingLandmark.extId})
                        </Text>
                    ) : undefined}
                </Flex>
            </div>
            <table className="desc-table">
                <tbody>
                    {tbDataSource.map((el, idx) => {
                        return (
                            <tr key={`tr-${idx}`}>
                                <td>{el[0]}</td>
                                <td>{el[1]}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <Divider style={{ margin: 0, borderWidth: 6 }} />
            <LandmarkSurveySection style={cardPadding} />
            <LandmarkStatsSection style={cardPadding} />
        </ProCard>
    );
};

export default LandmarkDetailSection;
