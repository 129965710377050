import 'mapbox-gl/dist/mapbox-gl.css';
import 'styles/map-style.css';
import React, { CSSProperties, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { LatLngDto } from 'apis/VenueApi';
import BaseMapEngine, { HK_CENTER } from 'services/BaseMapEngine';
import { Circle, MapContainer, Marker } from 'react-leaflet';
import MapTileControl from './MapTileControl';
import { useLeafletContext } from '@react-leaflet/core';

export type LocationPickerRef = {
    getCenter: () => LatLngDto | null;
    flyTo: (center: LatLngDto) => void;
};

export type LocationPickerProps = {
    style?: CSSProperties;
    center?: LatLngDto;
    onChange?: (center: LatLngDto) => void;
    editable?: boolean;
    animated?: boolean;
};

const LocationPickerContent = React.forwardRef<LocationPickerRef, LocationPickerProps>(
    (props, ref) => {
        const [center, setCenter] = useState<LatLngDto>(HK_CENTER);
        const [radius, setRadius] = useState<number | undefined>();
        const context = useLeafletContext();

        useEffect(() => {
            setTimeout(() => {
                context.map.invalidateSize();
            }, 250);
        }, []);

        useEffect(() => {
            context.map.on('move', function (e) {
                const center = context.map.getCenter();
                setCenter(center);
                if (props.onChange) props.onChange({ lat: center.lat, lng: center.lng });
            });
            if (props.center) context.map.panTo(props.center, { animate: false }),
            context.map.invalidateSize();
        }, [props.center]);

        useImperativeHandle(ref, () => ({
            getCenter: () => context.map.getCenter(),
            flyTo: (center) => context.map.panTo(center, { animate: false }),
            updateRadius: (radius?: number) => setRadius(radius),
        }));

        return (
            <>
                {radius ? (
                    <Circle
                        center={[center.lat, center.lng]}
                        radius={radius}
                        color="#F71B7E"
                        stroke={false}
                        interactive={false}
                    />
                ) : undefined}
                <Marker position={[center.lat, center.lng]} interactive={false} />
            </>
        );
    },
);

const LocationPicker = React.forwardRef<LocationPickerRef, LocationPickerProps>((props, ref) => {
    const initCenterRef = useRef<LatLngDto>(HK_CENTER);
    const contentRef = useRef<any>();

    useEffect(() => {
        const initCenter = props.center ?? HK_CENTER;
        initCenterRef.current = initCenter;
    }, [props.center]);

    useImperativeHandle(ref, () => ({
        getCenter() {
            return contentRef.current.getCenter();
        },
        flyTo(center) {
            return contentRef.current.flyTo(center, false);
        },
        updateRadius(radius?: number) {
            if (contentRef.current) contentRef.current.updateRadius(radius);
        },
    }));

    return (
        <div id="map-view" style={{ width: '100%', height: '100%', ...props.style }}>
            <MapContainer
                center={[initCenterRef.current.lat, initCenterRef.current.lng]}
                zoomSnap={0.7}
                zoom={18}
                scrollWheelZoom={true}
                zoomControl={false}
                style={{ position: 'relative', height: '100%', flex: 1 }}
            >
                <MapTileControl visible={false} />
                <LocationPickerContent ref={contentRef as any} {...props} />
            </MapContainer>
        </div>
    );
});
export default LocationPicker;
