import { ProCard } from '@ant-design/pro-components';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Space,
    Button,
    Typography,
    Flex,
    message,
    Checkbox,
    Tree,
    Tooltip,
    Empty,
    Divider,
    Collapse,
    Modal,
} from 'antd';
import { MobileOutlined, FolderOpenFilled } from '@ant-design/icons';
import { useOutdoorState } from 'providers/OutdoorProvider';
import { getLmSurveys, LmSurveysDto, updateLmSurveyRev } from 'apis/LandmarkApi';
import moment from 'moment';
import { useAuth } from 'providers/AuthProvider';
import LmSurveyDetailDialog from 'components/dialogs/LmSurveyDetailDialog';
const { Text } = Typography;

const LandmarkSurveySection: React.FC<{ style?: React.CSSProperties }> = (props) => {
    const { isSuperAdmin } = useAuth();
    const { workingLandmark } = useOutdoorState();
    const [lmSurvey, setLmSurvey] = useState<LmSurveysDto>();
    const [checkedRow, setCheckedRow] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const snapshotRef = useRef<string | undefined>();
    const [messageApi, contextHolder] = message.useMessage();
    const lmSurveys = useMemo(() => lmSurvey?.surveys ?? [], [lmSurvey]);
    const [modal, modalCtxHolder] = Modal.useModal();

    const refreshLmSurvey = () => {
        if (!workingLandmark?.id) return;
        getLmSurveys(workingLandmark.id).then((resp) => {
            const svData = resp.data?.surveys ?? [];
            const enabledIds = svData
                .filter((el) => el.enabled)
                .map((x) => x.id)
                .sort();
            setLmSurvey(resp.data);
            setCheckedRow(enabledIds);
            snapshotRef.current = JSON.stringify(enabledIds);
        });
    };

    const updateRevision = () => {
        if (!workingLandmark?.id) return;
        setIsLoading(true);
        updateLmSurveyRev(workingLandmark.id, { id: workingLandmark.id, whitelist: checkedRow })
            .then(() => {
                messageApi.success('Revision updated');
                refreshLmSurvey();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const showDetailDialog = (id: string) => {
        if (!workingLandmark || !isSuperAdmin) return;
        modal.info({
            title: 'Survey samples',
            width: '90%',
            style: { top: 20 },
            closable: true,
            content: <LmSurveyDetailDialog landmarkId={workingLandmark!.id} bid={id} />,
            footer: null,
        });
    };

    useEffect(() => {
        if (workingLandmark?.id && !!workingLandmark?.surveyRev) {
            refreshLmSurvey();
        } else {
            setLmSurvey(undefined);
        }
    }, [workingLandmark]);

    const hasChange = useMemo(() => {
        if (snapshotRef.current === undefined) return false;
        const enabledIds = JSON.stringify(checkedRow.sort());
        return enabledIds !== snapshotRef.current;
    }, [checkedRow]);

    const treeData = useMemo(() => {
        return lmSurveys.map((el) => {
            const deviceInfo = (
                <Space>
                    <MobileOutlined />
                    <span>
                        {el.deviceModel} ({el.deviceOs})
                    </span>
                </Space>
            );
            const rightLabel = `${el.meta?.len ?? 0} samples`;
            return {
                title: (
                    <Tooltip
                        title={deviceInfo}
                        color="grey"
                        placement="right"
                        trigger="contextMenu"
                    >
                        <Flex className="tree-title" justify="space-between" align="center">
                            <Space title={el.id} style={{ flex: 1 }}>
                                <FolderOpenFilled style={{ color: '#4164fb' }} />
                                <Text className="line-clamp">
                                    {moment(el.recordAt).format('YYYY-MM-DD HH:mm Z')}
                                </Text>
                            </Space>
                            <Typography.Text type="secondary">
                                {isSuperAdmin ? (
                                    <Button
                                        type="link"
                                        size="small"
                                        className="text-08"
                                        onClick={() => showDetailDialog(el.id)}
                                    >
                                        {rightLabel}
                                    </Button>
                                ) : (
                                    <span className="text-08">{rightLabel}</span>
                                )}
                            </Typography.Text>
                        </Flex>
                    </Tooltip>
                ),
                key: el.id,
            };
        });
    }, [lmSurveys]);

    const checkedPaths = checkedRow.length;
    const totalPaths = lmSurveys.length;
    const _renderContent = () => (
        <ProCard
            title={
                <Space style={{ marginLeft: 12 }}>
                    <Checkbox
                        indeterminate={checkedPaths !== totalPaths && checkedPaths > 0}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setCheckedRow(lmSurveys.map((x) => x.id));
                            } else {
                                setCheckedRow([]);
                            }
                        }}
                        checked={checkedPaths === totalPaths && totalPaths > 0}
                    />
                    <Text>Survey</Text>
                </Space>
            }
            subTitle={`${checkedPaths}/${totalPaths}`}
            headerBordered
            ghost
            size="small"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.02)' }}
            bodyStyle={{ padding: 0 }}
        >
            <Tree
                checkable
                showIcon
                blockNode
                onCheck={(checkedKeys, info) => {
                    setCheckedRow(checkedKeys as string[]);
                }}
                checkedKeys={checkedRow}
                treeData={treeData}
                rootClassName="survey-tree"
                rootStyle={{
                    backgroundColor: 'transparent',
                    padding: '6px 6px 6px 0',
                }}
            />
            {hasChange ? (
                <>
                    <Divider style={{ margin: 0 }} />
                    <Flex justify="right" align="center" gap={12} style={{ padding: 12 }}>
                        <Text type="warning">Note: You have unsaved change.</Text>
                        <Button
                            size="small"
                            onClick={() => {
                                setCheckedRow(JSON.parse(snapshotRef.current ?? '[]'));
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            size="small"
                            type="primary"
                            onClick={() => updateRevision()}
                            loading={isLoading}
                        >
                            Save
                        </Button>
                    </Flex>
                </>
            ) : undefined}
        </ProCard>
    );

    const content = (
        <Collapse
            ghost
            collapsible="header"
            expandIconPosition="end"
            bordered={false}
            style={{ padding: 0 }}
            defaultActiveKey={['lm-survey']}
            destroyInactivePanel
            size="small"
            items={[
                {
                    key: 'lm-survey',
                    label: <span className="ant-pro-card-title">Survey</span>,
                    styles: {
                        header: { paddingLeft: '12px' },
                        body: { padding: '0px 12px 12px 12px' },
                    },
                    children:
                        totalPaths === 0 ? (
                            <Empty description="No survey available." />
                        ) : (
                            <>
                                <Text type="secondary">
                                    Enable the surveys which you wish to include in the fingerprint.
                                </Text>
                                {_renderContent()}
                                {lmSurvey?.surveyRev ? (
                                    <Text type="secondary" className="text-08">
                                        Last update:{' '}
                                        {moment(lmSurvey.surveyRev * 1000).format(
                                            'YYYY-MM-DD HH:mm Z',
                                        )}
                                    </Text>
                                ) : undefined}
                            </>
                        ),
                },
            ]}
        />
    );
    return (
        <>
            {modalCtxHolder}
            {contextHolder}
            {content}
        </>
    );
};

export default LandmarkSurveySection;
