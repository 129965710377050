import { ProCard, ProList } from '@ant-design/pro-components';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, message, Spin, Typography, Flex, Input } from 'antd';
import { FilterOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { useAuth } from 'providers/AuthProvider';
import { useAppState } from 'providers/AppStateProvider';
import { useLocation } from 'react-router-dom';
import { ResourceRole } from 'apis/UserApi';
import CustomIcon from 'components/CustomIcon';
import { useOutdoorState } from 'providers/OutdoorProvider';
import { LandmarkDto, LmFilterDto } from 'apis/LandmarkApi';
import { BasicListTier } from 'components/BasicTier';
import LandmarkDetailSection from './LandmarkDetailSection';
import LandmarkInputModule from 'components/dialogs/LandmarkInputDialog';
import LandmarkMapView from 'components/maps/LandmarkMapView';

const LandmarkScreen: React.FC = () => {
    const { hasAccess } = useAuth();
    const {
        isLoading,
        venue,
        workingLandmark,
        selectLandmark,
        landmarks,
        lmPageConf,
        fetchLandmarks,
        filterCount,
    } = useOutdoorState();
    const { activeProject, project, isMobile, isTablet } = useAppState();
    const { state } = useLocation();
    const isEditor = hasAccess(ResourceRole.EDITOR, activeProject?.projId);
    const [keyword, setKeyword] = useState<string | undefined>();
    const drawRef = useRef<any>();
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        if (!!state?.lmId && !!landmarks) {
            selectLandmark(landmarks.find((el) => el.id === state.lmId));
        }
    }, []);

    useEffect(() => {
        if (drawRef.current?.resetAll) {
            const entity = !landmarks
                ? undefined
                : landmarks.map((el, idx) => {
                      return {
                          id: el.id,
                          label: el.name,
                          lat: el.position.lat,
                          lng: el.position.lng,
                      };
                  });
            drawRef.current.resetAll(entity);
        }
    }, [landmarks]);

    useEffect(() => {
        if (workingLandmark?.id) scrollToId(workingLandmark.id);
        if (drawRef.current) drawRef.current.selectById(workingLandmark?.id);
    }, [workingLandmark]);

    const scrollToId = (id: string) => {
        const element = document.querySelector(`[data-lm-id="${id}"]`);
        if (element) element.scrollIntoView({ behavior: 'smooth' });
    };

    const _handleKeywordSearch = (input: string) => {
        const tempFilter: LmFilterDto = { tags: [], q: null };
        input.split(';').forEach((x) => {
            const matched = x.match('^tag:([^,]+(,w+)*)$');
            if (matched) {
                tempFilter.tags = matched[1].split(',');
            } else {
                tempFilter.q = x;
            }
        });
        fetchLandmarks(tempFilter);
    };

    const filteredLms = useMemo(() => {
        if (!keyword) return landmarks ?? [];
        let matched;
        let tagFilter: undefined | string[] = undefined;
        if ((matched = keyword.match('^tag:([^,]+(,w+)*)$'))) {
            tagFilter = matched[1].toLowerCase().split(',');
        }
        return (landmarks ?? []).filter((el) => {
            if (tagFilter) {
                return (el.clusters ?? []).some((tag) => tagFilter?.includes(tag.toLowerCase()));
            }
            return (
                el.name.toLowerCase().includes(keyword) ||
                (el.address ?? '').includes(keyword) ||
                el.id.toLowerCase().includes(keyword)
            );
        });
    }, [landmarks, keyword]);

    const _renderFilterBlock = () => {
        if (!landmarks) return undefined;
        return (
            <Flex justify="center" align="center" gap={12}>
                {filterCount > 0 ? (
                    <Button
                        shape="round"
                        size="small"
                        icon={<FilterOutlined />}
                        onClick={() => {
                            fetchLandmarks(null);
                        }}
                    >
                        Clear {filterCount} filter
                    </Button>
                ) : undefined}
                {keyword ? (
                    <Typography.Text type="secondary">
                        {filteredLms.length} / {landmarks.length} map matches
                    </Typography.Text>
                ) : undefined}
            </Flex>
        );
    };

    const _renderLandmarkList = () => {
        return (
            <ProList<LandmarkDto>
                loading={isLoading}
                rowKey="id"
                size="small"
                className="tiny-row"
                style={{ flex: 1, overflowY: 'auto', paddingBottom: 16 }}
                itemLayout={isTablet ? 'vertical' : undefined}
                dataSource={filteredLms}
                renderItem={(item, index) => {
                    const hasRev = !!item.surveyRev;
                    let baseCls = 'w100 cursor-pointer';
                    if (item.id === workingLandmark?.id) {
                        baseCls += ` selected-row`;
                    }
                    return (
                        <Flex
                            data-lm-id={item.id}
                            className={baseCls}
                            gap={6}
                            style={{ padding: '4px 8px 4px 12px', borderBottom: '1px #eee solid' }}
                            onClick={(evt) => selectLandmark(item)}
                        >
                            <BasicListTier
                                style={{ flex: 1 }}
                                title={item.name}
                                description={
                                    <Typography.Text type="secondary" className="text-08" ellipsis>
                                        {item.address ?? item.id}
                                    </Typography.Text>
                                }
                            />
                            <CustomIcon
                                icon={hasRev ? 'survey' : 'survey_err'}
                                color={hasRev ? 'green' : 'red'}
                                size={18}
                                title="Surveyed"
                            />
                        </Flex>
                    );
                }}
                pagination={{
                    size: 'small',
                    hideOnSinglePage: true,
                    defaultCurrent: (lmPageConf.current?.page ?? 0) + 1,
                    defaultPageSize: lmPageConf.current?.limit ?? 50,
                    total: lmPageConf.current?.total ?? 1,
                    current: (lmPageConf.current?.page ?? 0) + 1,
                    pageSizeOptions: [50, 100, 500, 1000],
                    // showTotal: () => undefined,
                    onChange: (page, size) => {
                        fetchLandmarks({ limit: size, page: page - 1 });
                    },
                }}
            />
        );
    };

    const _renderMapInfo = () => {
        if (workingLandmark) return undefined;
        return (
            <div
                style={{ position: 'absolute', padding: 16, zIndex: 500, textAlign: 'center' }}
                className="w100"
            >
                <Button
                    shape="round"
                    icon={<SearchOutlined />}
                    onClick={() => {
                        fetchLandmarks({
                            bbox: drawRef.current.getBbox().toBBoxString(),
                            limit: 2000,
                        });
                    }}
                >
                    Search this area
                </Button>
            </div>
        );
    };

    const _renderMapSection = () => {
        return (
            <div className="w100 h100" style={{ backgroundColor: '#eee' }}>
                <LandmarkMapView
                    ref={drawRef as any}
                    center={workingLandmark?.position}
                    radius={workingLandmark?.radius}
                    onClick={({ id }) => {
                        selectLandmark(id);
                        scrollToId(id);
                    }}
                />
            </div>
        );
    };

    if (!project || !venue) return <Spin />;
    return (
        <ProCard
            style={{ height: '100%' }}
            split={isMobile ? 'horizontal' : 'vertical'}
            className="full-content-height"
        >
            {contextHolder}
            <ProCard
                colSpan={isMobile ? undefined : '30%'}
                className="venue-panel"
                bodyStyle={{ padding: 0, display: 'flex', flexDirection: 'column' }}
            >
                <Flex justify="space-between" gap={6} style={{ margin: 12 }}>
                    <Input.Search
                        key="map-search-box"
                        size="small"
                        allowClear
                        variant="filled"
                        enterButton={
                            <Button
                                icon={<SearchOutlined style={{ color: 'rgba(0,0,0,0.3)' }} />}
                                type="text"
                            />
                        }
                        onChange={(e) => {
                            setKeyword(e.target?.value?.toLowerCase());
                        }}
                        onSearch={(value) => {
                            _handleKeywordSearch(value);
                            setKeyword(undefined);
                        }}
                        placeholder="Search landmarks"
                    />
                    {isEditor ? (
                        <LandmarkInputModule
                            key="btn-add-venueMap"
                            venueId={venue!.id}
                            record={undefined}
                            trigger={
                                <Button size="small" type="dashed" icon={<PlusOutlined />}></Button>
                            }
                            onSuccess={(result) => {
                                fetchLandmarks();
                                if (result?.id) selectLandmark(result.id);
                                messageApi.success('Landmark added');
                            }}
                        />
                    ) : undefined}
                </Flex>
                {_renderFilterBlock()}
                {_renderLandmarkList()}
            </ProCard>
            <LandmarkDetailSection />
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: '#ddd',
                    position: 'relative',
                }}
            >
                {_renderMapInfo()}
                {_renderMapSection()}
            </div>
        </ProCard>
    );
};

export default LandmarkScreen;
