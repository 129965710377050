// restore auth state
// get auth state
// login
// logout
// change password

import { getPublicKey, LoginResultDto } from "apis/AuthApi";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import JSEncrypt from 'jsencrypt';

const lk_auth_key = "lk:auth";
export namespace EncryptionUtils {
    export function publicEncrypt(plainText: string) {
        try {
            const publicKey = (window as any).__lk_pub_key ?? '';
            const cipher = new JSEncrypt();
            cipher.setPublicKey(publicKey);
            const cipherText = cipher.encrypt(plainText);
            return cipherText;
        } catch(ex) {
            console.error(ex);
            return null;
        }
    }

    export async function aesEncrypt(plainText: string, key: string) {
        try {
            const encoder = new TextEncoder();
            const data = encoder.encode(plainText);
            const keyData = encoder.encode(key);
            const cryptoKey = await window.crypto.subtle.importKey(
                'raw',
                keyData,
                { name: 'AES-CBC', length: 256 },
                false,
                ['encrypt']
            );
            const iv = window.crypto.getRandomValues(new Uint8Array(16));
            const encrypted = await window.crypto.subtle.encrypt(
                { name: 'AES-CBC', iv },
                cryptoKey,
                data
            );
            const encryptedArray = new Uint8Array(encrypted);
            const result = new Uint8Array(iv.length + encryptedArray.length);
            result.set(iv);
            result.set(encryptedArray, iv.length);
            return btoa(String.fromCharCode(...result as any));
        } catch (err) {
            console.error('Encryption error:', err);
            return null;
        }
    }

    export async function aesDecrypt(cipherText: string, key: string) {
        try {
            const encoder = new TextEncoder();
            const keyData = encoder.encode(key);
            const cryptoKey = await window.crypto.subtle.importKey(
                'raw',
                keyData,
                { name: 'AES-CBC', length: 256 },
                false,
                ['decrypt']
            );
            const data = Uint8Array.from(atob(cipherText), c => c.charCodeAt(0));
            const iv = data.slice(0, 16);
            const encryptedData = data.slice(16);
            const decrypted = await window.crypto.subtle.decrypt(
                { name: 'AES-CBC', iv },
                cryptoKey,
                encryptedData
            );
            return new TextDecoder().decode(decrypted);
        } catch (err) {
            console.error('Decryption error:', err);
            return null;
        }
    }

    export function getAesKeyFromPk() {
        const publicKey = (window as any).__lk_pub_key ?? '';
        const len = publicKey.length;
        const start = Math.floor(len / 2);
        const key = publicKey.substring(start, start + 16);
        return key.length < 16 ? key.padEnd(16, '0') : key;
    }

    export async function setSecureValue(key: string, value: string) {
        const encKey = getAesKeyFromPk();
        const cipherText = await aesEncrypt(value, encKey);
        if (cipherText) localStorage.setItem(key, cipherText);
        else localStorage.removeItem(key)
    }

    export async function getSecureValue(key: string) {
        const encKey = getAesKeyFromPk();
        const cipherText = localStorage.getItem(key);
        if (cipherText) {
            const plainText = await aesDecrypt(cipherText, encKey)
            return plainText;
        }
        return null;
    }
}

export function setFirebaseEnabled(flag: boolean) {
    (window as any).__fr_enabled = flag;
}
export function isFirebaseEnabled() {
    return (window as any).__fr_enabled ?? true;
}


export async function restoreAuthState() {
    return new Promise(async (resolve, reject)=>{
        try {
            const resp = await getPublicKey();
            (window as any).__lk_pub_key = resp.data?.data;
        } catch(ex) {
            console.error('Failed to establish secure connection. Please try again later.');
            reject('PK_ERR');
        }
        if (isFirebaseEnabled()) {
            onAuthStateChanged(getAuth(), async (mUser) => {
                cleanHostedUser();
                resolve(mUser);
            }, (ex) => reject(ex));
        } else {
            try {
                const value = localStorage.getItem(lk_auth_key);
                if (!value) throw new Error('No auth data');
                const encKey = EncryptionUtils.getAesKeyFromPk();
                const plainText = await EncryptionUtils.aesDecrypt(value, encKey)
                if (!plainText) throw new Error('Cannot decrypt');
                const obj = JSON.parse(plainText);
                if (!obj?.uid) throw new Error('Invalid auth object');
                (window as any).__lk_access_token = obj.accessToken;
                resolve(obj);
            } catch (ex) {
                reject(ex)
            }
        }
    });
}

export async function saveHostedUser(dto: LoginResultDto) {
    const fbUser = {
        uid: dto.uid,
        providerId: 1,
        accessToken: dto.accessToken,
        expiresTs: dto.expiresTs,
    };
    (window as any).__lk_access_token = dto.accessToken;
    await EncryptionUtils.setSecureValue(lk_auth_key, JSON.stringify(fbUser));
    return fbUser;
}

export async function cleanHostedUser() {
    delete (window as any).__lk_access_token;
    localStorage.removeItem(lk_auth_key);
}

export async function getAuthToken() {
    if(isFirebaseEnabled()) {
        const mUser = getAuth().currentUser;
        const mAccessToken = mUser ? await mUser.getIdToken() : null;
        return mAccessToken;
    } else {
        return (window as any).__lk_access_token ?? null;
    }
}