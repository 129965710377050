import ApiClient from 'utils/NetworkManager';
import { LatLngDto } from './VenueApi';

export interface LandmarkDto {
    id: string;
    extId?: string;
    venueId: string;
    name: string;
    address?: string;
    position: LatLngDto;
    radius: number;
    clusters?: string[];
    level: number;
    isIndoor: boolean;
    surveyRev?: number;
    updatedAt: string;
}

export interface LmSurveyEnableDto {
    id: string;
    enabled: boolean;
}

export interface LmSurveysDto {
    hash?: string;
    surveyRev?: number;
    surveys: LmSurveyDto[];
}

export interface LmSurveyWhitelistDto {
    id: string;
    whitelist: string[];
}

export interface LmSurveyDto extends LmSurveyEnableDto {
    deviceOs?: string;
    deviceModel?: string;
    recordAt: Date;
    meta?: { wifi: number; gps: number, gt: boolean, len: number };
}

export interface LmStatisticFilter {
    dayOfWeek: number;
    fromDate: Date;
    toDate: Date;
}

export interface QuartilesDto {
    count: number;
    min: number;
    max: number;
    avg: number;
    q1: number;
    q2: number;
    q3: number;
}

export interface LmStatisticResultDto {
    popular: number[],
    quartiles: QuartilesDto,
    visited: number[][]
}

export interface LmFilterDto {
    page?: number;
    limit?: number;
    q?: string | null;
    tags?: string[];
    bbox?: number[];
}

export interface LmSurveyDetailDto {
    _id: string;
    landmarkId: string;
    deviceId: string;
    deviceOs: string;
    deviceModel: string;
    meta: any;
    samples: {
        wifi: string[][];
        gps: string[][];
        ble?: string[][];
        gt?: number[];
    }[];
    createdAt: string;
    updatedAt: string;
}

export const getLandmarkList = (id: string, filter: LmFilterDto) => ApiClient.get<LandmarkDto[]>('/landmark/list', { id, ...filter });
export const createLandmark = (data: Omit<LandmarkDto, 'id'>) => ApiClient.post<LandmarkDto>('/landmark', data);
export const updateLandmark = (id: string, data: LandmarkDto) => ApiClient.put(`/landmark/${id}`, data);
export const deleteLandmark = (id: string) => ApiClient.delete(`/landmark/${id}`);
export const getLmSurveys = (id: string) => ApiClient.get<LmSurveysDto>(`/landmark/${id}/survey`);
export const updateLmSurveyRev = (id: string, data: LmSurveyWhitelistDto) => ApiClient.put(`/landmark/${id}/survey`, data);
export const getLmSurveyDetail = (id: string, bid: string) => ApiClient.get<LmSurveyDetailDto>(`/landmark/${id}/survey/${bid}`);
export const getLmStatistics = (id: string, filter: LmStatisticFilter) => ApiClient.post<LmStatisticResultDto>(`/landmark/${id}/statistic`, filter);
export const getZonalPkg = (venueId: string) => ApiClient.post(`/landmark/package`, { venueId }, { responseType: 'arraybuffer' });
