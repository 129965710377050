import {
    PageContainer,
    ProCard,
    ProDescriptions,
    ProTable,
    StatisticCard,
} from '@ant-design/pro-components';
import React, { useEffect, useMemo, useState } from 'react';
import { Space, Button, message, Typography, Tag, Flex, Divider, Steps, Input, Modal } from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    CopyOutlined,
    DownloadOutlined,
    FilterOutlined,
} from '@ant-design/icons';
import {
    AddProjVenueModule,
    CloneProjVenueModule,
    showDeleteVenueDialog,
} from 'components/dialogs/HandleProjVenueDialog';
import { useAuth } from 'providers/AuthProvider';
import { useAppState } from 'providers/AppStateProvider';
import { useNavigate } from 'react-router-dom';
import { ResourceRole } from 'apis/UserApi';
import BaseMapEngine from 'services/BaseMapEngine';
import CachedImage from 'components/CachedImage';
import CustomIcon from 'components/CustomIcon';
import { useOutdoorState } from 'providers/OutdoorProvider';
import { getLandmarkList, LandmarkDto } from 'apis/LandmarkApi';
import { VenueType } from 'apis/VenueApi';
const { Text } = Typography;
const mapEngine = new BaseMapEngine();

const OutdoorOverviewScreen: React.FC = () => {
    const { hasAccess } = useAuth();
    const {
        fetchLandmarks,
        isLoading,
        venue,
        landmarks,
        lmPageConf,
        availableClusters,
        filterCount,
        venueState,
    } = useOutdoorState();
    const { activeProject, project, refreshProject, isMobile, isTablet } = useAppState();
    const navigate = useNavigate();
    const isEditor = hasAccess(ResourceRole.EDITOR, activeProject?.projId);
    const isAdmin = hasAccess(ResourceRole.ADMIN, activeProject?.projId);
    const [keyword, setKeyword] = useState<string | undefined>(undefined);
    const [modal, modalCtxHolder] = Modal.useModal();
    const [messageApi, msgCtxHolder] = message.useMessage();

    useEffect(() => {
        setKeyword(undefined);
    }, [venue]);

    const _exportLandmarks = () => {
        if (!venue) return;
        const info = { id: venue.id, name: venue.name };
        const csvData = [
            'venueId,venueName,lmId,name,address,indoor,level,lat,lng,radius,clusters,covered',
        ];
        getLandmarkList(venue.id, { page: 0, limit: Number.MAX_SAFE_INTEGER }).then((resp) => {
            const data = resp.data ?? [];
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                const row = [
                    info.id,
                    info.name,
                    item.id,
                    item.name,
                    `"${item.address ?? ''}"`,
                    item.isIndoor,
                    item.level,
                    item.position.lat,
                    item.position.lng,
                    item.radius,
                    `"${(item.clusters ?? []).join(',')}"`,
                    !!item.surveyRev,
                ];
                csvData.push(row.join(','));
            }
            const blob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `${info.name}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    const filteredLms = useMemo(() => {
        if (!keyword) return landmarks ?? [];
        const mKeyword = keyword.toLowerCase();

        return (landmarks ?? []).filter((el) => {
            const fullText =
                el.name.toLowerCase().includes(mKeyword) ||
                el.id.toLowerCase().includes(mKeyword) ||
                `${el.level}`.includes(mKeyword);
            if (fullText) return true;

            let subFilter = false;
            let matched;
            if ((matched = mKeyword.match('survey=(true|false)'))) {
                const flag = matched[1] === 'true';
                if (!(!!el?.surveyRev === flag)) return false;
                else subFilter = true;
            }
            return subFilter;
        });
    }, [landmarks, keyword]);

    const clusterItems = useMemo(() => {
        return availableClusters.map((el) => ({ text: el!, value: el! }));
    }, [availableClusters]);

    const availableNames = useMemo(() => {
        return Array.from(new Set((landmarks ?? []).map((el) => el.name)));
    }, [landmarks]);

    function _renderLandmarkTable() {
        return (
            <ProTable<LandmarkDto>
                className="tiny-table"
                rowKey="id"
                rowClassName="cursor-pointer"
                size="small"
                loading={isLoading}
                ghost
                headerTitle={false}
                search={false}
                options={false}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'id',
                        filterSearch: true,
                        width: 50,
                        copyable: true,
                        ellipsis: true,
                        render: (value, record, index) => (
                            <Text className="text-08" style={{ fontFamily: 'monospace' }}>
                                {value}
                            </Text>
                        ),
                        filters: filteredLms.map((el) => ({ text: el.id, value: el.id })),
                        onFilter: (value, record) => record.id.includes(value as string),
                        sorter: (a, b) => a.id.localeCompare(b.id),
                    },
                    {
                        title: 'Name / Address',
                        dataIndex: 'name',
                        width: 250,
                        filterSearch: true,
                        filters: availableNames.map((el) => ({ text: el, value: el })),
                        ellipsis: true,
                        onFilter: (value, record) => record.name.includes(value as string),
                        sorter: (a, b) => a.name.localeCompare(b.name),
                        render: (value, record, index) => {
                            return (
                                <Text>
                                    <span>{record.name}</span>
                                    <Text
                                        style={{ marginLeft: 8 }}
                                        className="text-08"
                                        type="secondary"
                                    >
                                        {record.address}
                                    </Text>
                                </Text>
                            );
                        },
                    },
                    {
                        title: 'Cluster',
                        dataIndex: 'clusters',
                        width: 80,
                        render: (value, record) => {
                            const mClusters = record.clusters ?? [];
                            return (
                                <div>
                                    {mClusters.slice(0, 2).map((el, i) => (
                                        <Tag key={`c${i}-${el}`} className="rounded-tag">
                                            {el}
                                        </Tag>
                                    ))}
                                    {mClusters.length > 2 ? (
                                        <Tag className="rounded-tag">+{mClusters.length - 2}</Tag>
                                    ) : undefined}
                                </div>
                            );
                        },
                        filterSearch: true,
                        filters: clusterItems,
                        onFilter: (value, record) =>
                            (record.clusters ?? []).includes(value as string),
                    },
                    {
                        title: '',
                        dataIndex: 'survey',
                        filters: [
                            { text: 'Surveyed', value: true },
                            { text: 'No Survey', value: false },
                        ],
                        width: 18,
                        render: (value, record) => {
                            const hasRev = !!record?.surveyRev;
                            return (
                                <center>
                                    <CustomIcon
                                        icon={hasRev ? 'survey' : 'survey_err'}
                                        color={hasRev ? 'green' : 'red'}
                                        size={18}
                                        title="Surveyed"
                                    />
                                </center>
                            );
                        },
                        onFilter: (value, record) => value === !!record?.surveyRev,
                    },
                ]}
                dataSource={filteredLms}
                onRow={(record, index) => {
                    return {
                        onClick: (event) => {
                            if (record.venueId) {
                                navigate(`/project/outdoor/${record.venueId}/landmark`, {
                                    state: { lmId: record.id },
                                });
                            }
                        },
                    };
                }}
                pagination={{
                    defaultCurrent: (lmPageConf.current?.page ?? 0) + 1,
                    defaultPageSize: lmPageConf.current?.limit ?? 50,
                    total: lmPageConf.current?.total ?? 1,
                    current: (lmPageConf.current?.page ?? 0) + 1,
                    pageSizeOptions: [50, 100, 500, 1000],
                    onChange: (page, size) => {
                        fetchLandmarks({ limit: size, page: page - 1 });
                    },
                }}
            />
        );
    }

    function _renderInfoArea() {
        if (!venue) return <></>;
        return (
            <>
                <ProCard ghost>
                    <ProDescriptions column={1} layout="vertical" dataSource={venue}>
                        <ProDescriptions.Item label="Name" dataIndex="name" />
                        <ProDescriptions.Item label="ID" dataIndex="id" copyable />
                        <ProDescriptions.Item label="Center">
                            {venue.center.lat + ',' + venue.center.lng}
                        </ProDescriptions.Item>
                        <ProDescriptions.Item label="Type">
                            {venue.type === VenueType.OUTDOOR ? 'OUTDOOR' : 'INDOOR'}
                        </ProDescriptions.Item>
                        <ProDescriptions.Item>
                            <CachedImage
                                style={{ width: '100%', height: 180, objectFit: 'cover' }}
                                alt="Map"
                                src={mapEngine.getStaticImageUrl(
                                    venue.center.lat,
                                    venue.center.lng,
                                )}
                            />
                        </ProDescriptions.Item>
                    </ProDescriptions>
                </ProCard>
                <Divider />
                <ProCard ghost>
                    <Flex justify="space-between">
                        {isEditor && project ? (
                            <Space>
                                <AddProjVenueModule
                                    key="btn-edit-venue"
                                    projectId={project.id}
                                    isEdit={true}
                                    editingVenue={venue}
                                    trigger={<Button icon={<EditOutlined />}>Edit</Button>}
                                    onSuccess={() => {
                                        refreshProject(activeProject?.projId);
                                        // fetchVenue(venue.id);
                                        messageApi.success('Venue updated');
                                    }}
                                />
                                <CloneProjVenueModule
                                    key="btn-clone-venue"
                                    projId={project.id}
                                    venueInfo={venue}
                                    trigger={<Button icon={<CopyOutlined />}>Copy</Button>}
                                    onSuccess={() => {
                                        refreshProject(activeProject?.projId);
                                        // fetchVenue(venue.id);
                                        messageApi.success('Venue copied');
                                    }}
                                />
                            </Space>
                        ) : undefined}
                        {isAdmin ? (
                            <Button
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    showDeleteVenueDialog(modal, venue)
                                        .then((resp) => {
                                            refreshProject(activeProject?.projId);
                                            messageApi.success('Venue deleted');
                                            navigate('/project/venue', { replace: true });
                                        })
                                        .catch(() => {});
                                }}
                            >
                                Delete
                            </Button>
                        ) : undefined}
                    </Flex>
                </ProCard>
            </>
        );
    }

    const mLmCount = lmPageConf.current?.total ?? landmarks?.length ?? 0;
    return (
        <PageContainer breadcrumb={undefined} title={null} header={{ title: undefined }}>
            {modalCtxHolder}
            {msgCtxHolder}
            <ProCard
                ghost
                gutter={8}
                bodyStyle={{ flexDirection: isMobile ? 'column-reverse' : 'row' }}
            >
                <ProCard ghost split="horizontal">
                    <ProCard
                        bordered
                        title={`Landmarks (${!!keyword && keyword.length > 0 ? filteredLms.length + '/' : ''}${mLmCount})`}
                        headStyle={{ paddingBottom: 0 }}
                        extra={
                            <Space key="lm_ov_ext_group">
                                {mLmCount > 0 ? (
                                    <Input.Search
                                        key="map-search-box"
                                        size="small"
                                        allowClear
                                        onSearch={(val) => setKeyword(val)}
                                        placeholder="Search a landmark"
                                    />
                                ) : undefined}
                                {filterCount > 0 ? (
                                    <Button
                                        key="btn_clean_filter"
                                        shape="round"
                                        size="small"
                                        icon={<FilterOutlined />}
                                        onClick={() => {
                                            fetchLandmarks(null);
                                        }}
                                    >
                                        Clear {filterCount} filter
                                    </Button>
                                ) : undefined}
                            </Space>
                        }
                        actions={
                            venue && mLmCount > 0 ? (
                                <center>
                                    <Button
                                        style={{ color: 'grey' }}
                                        type="text"
                                        size="small"
                                        className="text-08"
                                        icon={<DownloadOutlined />}
                                        onClick={() => _exportLandmarks()}
                                    >
                                        Export landmarks info (CSV)
                                    </Button>
                                </center>
                            ) : undefined
                        }
                    >
                        {_renderLandmarkTable()}
                    </ProCard>
                </ProCard>
                <ProCard colSpan={isMobile ? undefined : 10} ghost direction="column">
                    <ProCard ghost>
                        <Flex justify="space-around" gap="small">
                            <StatisticCard
                                statistic={{
                                    title: 'Total landmarks',
                                    value: mLmCount,
                                }}
                                loading={isLoading}
                            />
                            <StatisticCard
                                statistic={{
                                    title: 'Status',
                                    value: ['Setup Landmark', 'Ready to survey', 'Build your app'][
                                        venueState
                                    ],
                                    description: isTablet ? undefined : (
                                        <Steps
                                            size="small"
                                            current={venueState}
                                            items={[{}, {}, {}]}
                                        />
                                    ),
                                }}
                                loading={isLoading}
                            />
                        </Flex>
                    </ProCard>
                    <ProCard bordered style={{ margin: '8px 0' }}>
                        {_renderInfoArea()}
                    </ProCard>
                </ProCard>
            </ProCard>
        </PageContainer>
    );
};

export default OutdoorOverviewScreen;
