import { ModalForm } from '@ant-design/pro-components';
import { Form, Button, Space } from 'antd';
import { BaseDialogProps } from './BaseDialogProps.type';
import { useRef, useState } from 'react';
import { SwapOutlined } from '@ant-design/icons';
import CodeEditor, { CodeEditorRef } from 'components/CodeEditor';
import FormItem from 'antd/es/form/FormItem';

export type PolygonInputProp = BaseDialogProps & {
    getPosition?: () => number[][] | undefined;
    positions?: number[][];
    targetId?: number | string;
};

export const PolygonInputModule: React.FC<PolygonInputProp> = (props) => {
    const editorRef = useRef<CodeEditorRef>();
    const [polygonInputForm] = Form.useForm();
    const [fixBtn, setFixBtn] = useState<boolean>(false);

    const _fixLastEl = () => {
        const objArr = JSON.parse(polygonInputForm.getFieldValue('polygon'));
        objArr.push(objArr[0]);
        const strValue = JSON.stringify(objArr);
        polygonInputForm.setFieldValue('polygon', strValue);
        if (editorRef.current) editorRef.current.setValue(strValue);
        polygonInputForm.validateFields();
    };

    const _shiftCoordinate = () => {
        try {
            const value = polygonInputForm.getFieldValue('polygon');
            const arrObj = JSON.parse(value);
            if (!Array.isArray(arrObj)) throw new Error('Not array');
            const shifted = arrObj.map((el) => [el[1], el[0]]);
            const strValue = JSON.stringify(shifted);
            polygonInputForm.setFieldValue('polygon', strValue);
            if (editorRef.current) editorRef.current.setValue(strValue);
        } catch (ex) {
            /* do nothing */
        }
    };

    return (
        <ModalForm
            form={polygonInputForm}
            title={'Import polygon'}
            trigger={props.trigger}
            width={'80%'}
            submitter={{
                render: (submitterProps) => {
                    return (
                        <Button type="primary" onClick={submitterProps.submit}>
                            Preview
                        </Button>
                    );
                },
            }}
            onOpenChange={(isOpen) => {
                if (isOpen) {
                    const pos =
                        (props?.getPosition ? props.getPosition() : props.positions) ??
                        props.positions;
                    if (Array.isArray(pos) && pos.length >= 3) {
                        const shifted = pos.map((el: any) => [el[1], el[0]]);
                        const strValue = JSON.stringify(shifted);
                        polygonInputForm.setFieldValue('polygon', strValue);
                        if (editorRef.current) editorRef.current.setValue(strValue);
                    }
                }
            }}
            onFinish={async (values) => {
                const strPolygon = values['polygon'];
                try {
                    const polygonArrObj = JSON.parse(strPolygon);
                    const shifted = polygonArrObj.map((el: any) => {
                        return [el[0], el[1]];
                    });
                    if (props.onSuccess)
                        props.onSuccess({ coordinates: shifted, id: props.targetId });
                } catch (ex) {
                    console.error(ex);
                }
                return true;
            }}
        >
            <p>
                Input polygon coordinate array. i.e. <i>[[lng, lat], [lng, lat], ...]</i>
            </p>
            <FormItem
                name="polygon"
                label={
                    <Space>
                        <span>Coordinates</span>
                        <Button
                            type="text"
                            size="small"
                            title="Shift lat/lng"
                            icon={<SwapOutlined style={{ color: 'grey' }} />}
                            onClick={() => _shiftCoordinate()}
                        />
                    </Space>
                }
                rules={[
                    { required: true },
                    () => ({
                        validator(_, value) {
                            try {
                                const arrObj = JSON.parse(value);
                                if (!Array.isArray(arrObj)) throw new Error('Not array');
                                const mLength = arrObj.length;
                                if (mLength < 4) {
                                    throw new Error('Polygon must have 4 or more Positions');
                                }
                                for (let i = 0; i < mLength; i++) {
                                    if (arrObj[i].length !== 2) throw new Error('Invalid length');
                                    if (
                                        typeof arrObj[i][0] !== 'number' ||
                                        typeof arrObj[i][0] !== 'number'
                                    )
                                        throw new Error('Invalid data type');
                                }
                                if (
                                    arrObj[0][0] !== arrObj[mLength - 1][0] ||
                                    arrObj[0][1] !== arrObj[mLength - 1][1]
                                ) {
                                    setFixBtn(true);
                                    throw new Error(
                                        'The first and last coordinate must be the same.',
                                    );
                                }
                                setFixBtn(false);
                                return Promise.resolve();
                            } catch (ex) {
                                return Promise.reject(new Error('Invalid coordinate array: ' + ex));
                            }
                        },
                    }),
                ]}
            >
                <CodeEditor
                    ref={editorRef as any}
                    className="input-bordered"
                    placeholder={`[\n  [lng, lat],\n  [lng, lat],\n  ...\n]`}
                    height={300}
                    lineWrapping
                />
            </FormItem>
            {fixBtn ? (
                <Button type="link" size="small" onClick={_fixLastEl}>
                    Fix it
                </Button>
            ) : undefined}
        </ModalForm>
    );
};
