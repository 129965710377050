import React, { useMemo } from 'react';

interface PieProgressProps {
    percent: number;
    size?: number;
    color?: string;
    backgroundColor?: string;
    title?: string;
}

const PieProgress: React.FC<PieProgressProps> = ({
    percent,
    size = 50,
    color = '#1677FF',
    backgroundColor = '#DDDDDD',
    title,
}) => {
    const pathData = useMemo(() => {
        if (percent < 0) return '';
        // Ensure percent is between 0 and 100
        const normalizedPercent = Math.min(Math.max(percent, 0), 100);

        // Calculate the angle for the slice
        const angle = (normalizedPercent / 100) * 360;

        // Calculate coordinates for the slice
        const angleInRadians = ((angle - 90) * Math.PI) / 180.0;
        const x = Math.cos(angleInRadians);
        const y = Math.sin(angleInRadians);

        // Determine if we need to use the large arc flag
        const largeArcFlag = angle > 180 ? 1 : 0;
        const r = size / 2;
        // Create the SVG path for the slice
        if (normalizedPercent === 100) {
            return `
    M ${r} ${r}
    m ${r}, 0
    a ${r},${r} 0 1,1 -${size},0
    a ${r},${r} 0 1,1  ${size},0
`;
        } else {
            return `
    M ${r} ${r}
    L ${r} 0
    A ${r} ${r} 0 ${largeArcFlag} 1 ${(x * size) / 2 + r} ${(y * size) / 2 + r}
    L ${r} ${r}
`;
        }
    }, [percent]);

    return (
        <div title={title}>
            <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
                {/* Background circle */}
                <circle cx={size / 2} cy={size / 2} r={size / 2} fill={backgroundColor} />
                {/* Slice */}
                <path d={pathData} fill={color} />
            </svg>
        </div>
    );
};

export default React.memo(PieProgress, (prev, curr) => prev.percent === curr.percent);
