import React, { useEffect, useMemo, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { getLmSurveyDetail, LmSurveyDetailDto } from 'apis/LandmarkApi';
import { Button, Collapse, Divider, Spin } from 'antd';
import moment from 'moment';

const LmSurveyDetailDialog: React.FC<{ landmarkId: string; bid: string }> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<LmSurveyDetailDto>();

    const _fetchDetail = () => {
        setIsLoading(true);
        getLmSurveyDetail(props.landmarkId, props.bid)
            .then((resp) => {
                setData(resp.data);
            })
            .finally(() => setIsLoading(false));
    };

    const _exportJson = () => {
        if (!data) return;
        const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data));
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute('href', dataStr);
        downloadAnchorNode.setAttribute('download', data._id + '.json');
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    };

    useEffect(() => {
        if (!!props.bid && !!props.landmarkId) _fetchDetail();
    }, [props.bid, props.landmarkId]);

    const arrayToTable = (array: any[][], header: string[]) => {
        return (
            <table className="basic-table">
                <thead>
                    <tr>
                        {header.map((column, index) => (
                            <th key={index}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {array.map((row, index) => (
                        <tr key={index}>
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex}>{cell}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    const infoItems = useMemo(
        () =>
            data
                ? [
                      ['Survey ID', data._id],
                      ['Landmark ID', data.landmarkId],
                      ['Device ID', data.deviceId],
                      ['Device OS', data.deviceOs],
                      ['Device Model', data.deviceModel],
                      ['Record At', moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss Z')],
                  ]
                : [],
        [data],
    );

    const sampleItems = useMemo(() => {
        if (!data) return [];
        return data.samples.map((sample, index) => {
            return {
                key: `s${index}`,
                label: <b>Sample {index + 1}</b>,
                children: (
                    <>
                        <b>WiFi: ({sample.wifi.length})</b>
                        {arrayToTable(sample.wifi, ['SSID', 'BSSID', 'RSSI'])}
                        <b>GPS:</b>
                        {arrayToTable(sample.gps, ['lat', 'lng', 'alt', 'hor_acc', 'vert_acc'])}
                        <b>BLE: ({(sample.ble ?? []).length})</b>
                        {arrayToTable(sample.ble ?? [], ['UUID', 'Major', 'Minor', 'RSSI'])}
                        <b>Ground Truth:</b>
                        {sample.gt ? arrayToTable([sample.gt], ['lat', 'lng']) : <div>NA</div>}
                    </>
                ),
            };
        });
    }, [data]);

    if (isLoading) return <Spin />;
    return (
        <div>
            <table className="desc-table tiny-row">
                <tbody>
                    {infoItems.map((item, index) => (
                        <tr key={`info-${index}`}>
                            <td>{item[0]}</td>
                            <td>{item[1]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Button icon={<DownloadOutlined />} onClick={() => _exportJson()}>
                Download JSON
            </Button>
            <Divider />
            <Collapse
                size="small"
                items={sampleItems}
                defaultActiveKey={(data?.samples ?? []).map((x, idx) => `s${idx}`)}
            />
        </div>
    );
};

export default LmSurveyDetailDialog;
