import { PageContainer } from '@ant-design/pro-components';
import React, { useEffect, useState } from 'react';
import { Space, Button, message, Input, Card, List, Badge } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { AddProjVenueModule } from 'components/dialogs/HandleProjVenueDialog';
import { useAuth } from 'providers/AuthProvider';
import { useAppState } from 'providers/AppStateProvider';
import { useNavigate } from 'react-router-dom';
import { ResourceRole } from 'apis/UserApi';
import BaseMapEngine from 'services/BaseMapEngine';
import { useVenueState } from 'providers/VenueProvider';
import CachedImage from 'components/CachedImage';
import { VenueType } from 'apis/VenueApi';

const VenueListScreen: React.FC = () => {
    const mapEngine = new BaseMapEngine();
    const { hasAccess } = useAuth();
    const { isLoading, switchVenue } = useVenueState();
    const { activeProject, project, refreshProject, isProjectLoading } = useAppState();
    const [venueSearch, setVenueSearch] = useState<string>('');
    const isEditor = hasAccess(ResourceRole.EDITOR, activeProject?.projId);

    useEffect(() => {
        if (!!activeProject && activeProject.projId != project?.id) {
            refreshProject(activeProject?.projId);
        }
    }, [activeProject]);

    const navigate = useNavigate();

    const _renderExtra = () => {
        if (!isEditor || !project) return undefined;
        return (
            <Space>
                <AddProjVenueModule
                    key="btn-add-venue"
                    projectId={project.id}
                    isEdit={false}
                    editingVenue={{}}
                    trigger={
                        <Button type="primary" icon={<PlusOutlined />}>
                            Add venue
                        </Button>
                    }
                    onSuccess={() => {
                        refreshProject(activeProject?.projId);
                        message.success('Venue added');
                    }}
                />
                <Input
                    placeholder="Search venue"
                    style={{ width: '150px' }}
                    prefix={<SearchOutlined />}
                    onChange={(event) => {
                        setVenueSearch(event.target.value);
                    }}
                />
            </Space>
        );
    };

    return (
        <PageContainer title="Venues" breadcrumb={undefined} extra={_renderExtra()}>
            <List
                loading={isProjectLoading || isLoading}
                rowKey="id"
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 4,
                    xl: 4,
                    xxl: 6,
                }}
                renderItem={(item, index) => {
                    const mCard = (
                        <Card
                            hoverable
                            cover={
                                <CachedImage
                                    height={150}
                                    alt={item.name}
                                    src={mapEngine.getStaticImageUrl(
                                        item.center.lat,
                                        item.center.lng,
                                    )}
                                    style={{ objectFit: 'cover' }}
                                />
                            }
                            onClick={() => {
                                if (item.type === VenueType.OUTDOOR) {
                                    navigate(`/project/outdoor/${item.id}`);
                                } else {
                                    switchVenue(item.id);
                                    navigate(`/project/venue/${item.id}`);
                                }
                            }}
                        >
                            <Card.Meta title={item.name} />
                        </Card>
                    );
                    return (
                        <List.Item>
                            {item?.type === VenueType.OUTDOOR ? (
                                <Badge.Ribbon text="Worldwide" color="purple">
                                    {mCard}
                                </Badge.Ribbon>
                            ) : (
                                mCard
                            )}
                        </List.Item>
                    );
                }}
                dataSource={project?.venues.filter(
                    (venue) =>
                        venue.name.toLowerCase().includes(venueSearch.toLowerCase()) ||
                        venue.id.toLowerCase().includes(venueSearch.toLowerCase()),
                )}
            />
        </PageContainer>
    );
};

export default VenueListScreen;
