import { useEffect, useMemo, useState } from 'react';
import Plot from 'react-plotly.js';
import { ProCard } from '@ant-design/pro-components';
import { useTraceContext } from 'providers/TraceProvider';
import { TabsProps } from 'antd';

const baseLayoutConf = {
    autosize: true,
    width: undefined,
    responsive: true,
    margin: {
        r: 5,
        b: 20,
        t: 10,
    },
    showlegend: false,
    xaxis: {
        showgrid: false,
        zeroline: true,
        tickformat: '%H:%M',
    },
};

const baseConfig: Partial<Plotly.Config> = {
    displayModeBar: false,
    responsive: true,
};

export const TraceSampleSection: React.FC<{ ts?: number }> = ({ ts }) => {
    const [plotData, setPlotData] = useState<{
        dataSet: any[];
        yLabels: string[];
        range: [number, number];
    }>({ dataSet: [], yLabels: [], range: [Infinity, Infinity] });
    const [subCatData, setSubCatData] = useState<any[]>([]);
    const traceDetail = useTraceContext().traceDetail!;

    useEffect(() => {
        const dataList = [];
        const names = [];
        let startTs = Infinity;
        let endTs = -Infinity;
        for (let i = 0; i < traceDetail.sample.length; i++) {
            const mSample = traceDetail.sample[i].data;
            const _x = [];
            const _y = [];
            const _z_text = [];
            for (let j = 0; j < mSample.length; j++) {
                _x.push(new Date(mSample[j][0]));
                _y.push(i + 1);
                _z_text.push(mSample[j].length > 2 ? mSample[j][2] : mSample[j][1]);
                if (mSample[j][0] < startTs) startTs = mSample[j][0];
                if (mSample[j][0] > endTs) endTs = mSample[j][0];
            }
            dataList.push({
                x: _x,
                y: _y,
                text: _z_text,
                mode: 'markers',
                name: traceDetail.sample[i].name,
                hovertemplate:
                    '<b>%{y}: %{text}</b><br>' +
                    'Time: %{x:%Y-%m-%d %H:%M:%S}<br>' +
                    '<extra></extra>',
                marker: { color: traceDetail.sample[i]._color },
            });
            names.push(traceDetail.sample[i].name);
        }
        setPlotData({
            dataSet: dataList,
            yLabels: names,
            range: [startTs, endTs],
        });
    }, [traceDetail]);

    function _buildDataSet(category: string) {
        if (!traceDetail) return;
        const record = traceDetail.sample.find((el) => el.name === category);
        if (!record) return;
        const dataList: any[] = [];
        const mSample = record?.data ?? [];
        const len = mSample.length > 0 ? mSample[0].length - 1 : 0;
        const label = record?.yLabel ?? record.name;
        for (let k = 0; k < len; k++) {
            if (mSample.length > 0) {
                const isNotValid = isNaN(Number(mSample[0][k + 1]));
                if (isNotValid) continue;
            }
            const _x = [];
            const _y = [];
            for (let j = 0; j < mSample.length; j++) {
                _x.push(new Date(mSample[j][0]));
                _y.push(mSample[j][k + 1]);
            }
            const mLabel = len === 1 && k == 0 ? label : `${label} ${k + 1}`;
            dataList.push({
                x: _x,
                y: _y,
                mode: 'lines',
                name: mLabel,
                yLabel: record?.yLabel,
                hovertemplate:
                    `<b>${mLabel}: %{y}</b><br>` +
                    'Time: %{x:%Y-%m-%d %H:%M:%S}<br>' +
                    '<extra></extra>',
                // marker: { color: record._color },
                connectgaps: len === 1,
            });
        }
        setSubCatData(dataList);
    }

    const chartShape = useMemo<Partial<Plotly.Shape>[]>(
        () => [
            {
                visible: ts !== undefined,
                type: 'line',
                x0: ts ?? 0,
                y0: 0,
                x1: ts ?? 0,
                yref: 'paper',
                y1: 1,
                line: {
                    color: 'grey',
                    width: 2,
                    dash: 'dot',
                },
            },
            {
                type: 'rect',
                xref: 'x',
                yref: 'paper',
                x0: plotData.range[0],
                x1: plotData.range[1],
                y0: 0,
                y1: 1,
                fillcolor: '#d3d3d3',
                opacity: 0.2,
                line: {
                    width: 0,
                },
            },
        ],
        [plotData.range, ts],
    );

    const overviewLayout = useMemo<Partial<Plotly.Layout>>(
        () => ({
            ...baseLayoutConf,
            height: 40 * plotData.yLabels.length,
            yaxis: {
                ticktext: plotData.yLabels,
                tickvals: Array.from({ length: plotData.yLabels.length }, (_, i) => i + 1),
                showline: false,
                zeroline: true,
                range: [0, plotData.yLabels.length + 1],
            },
            shapes: chartShape,
        }),
        [chartShape, plotData],
    );

    const singleLayout = useMemo<Partial<Plotly.Layout>>(
        () => ({
            ...baseLayoutConf,
            height: 250,
            showlegend: subCatData.length > 1,
            yaxis: {
                title: subCatData.length > 0 ? subCatData[0].yLabel ?? 'Count' : 'Count',
                showline: false,
                zeroline: true,
            },
            shapes: chartShape,
        }),
        [chartShape, subCatData],
    );

    const items: TabsProps['items'] = [
        {
            key: 'sa-overview',
            label: 'Sample overview',
            children: (
                <Plot
                    style={{ flex: 1 }}
                    data={plotData.dataSet}
                    layout={overviewLayout}
                    config={baseConfig}
                />
            ),
        },
        ...(traceDetail.sample ?? []).map((el, index) => {
            return {
                key: el.name,
                label: el.name,
                children: (
                    <Plot
                        style={{ flex: 1 }}
                        data={subCatData}
                        layout={singleLayout}
                        config={baseConfig}
                    />
                ),
            };
        }),
    ];
    return (
        <ProCard
            style={{ flex: 1 }}
            tabs={{
                items: items,
                defaultActiveKey: 'sa-overview',
                onChange: _buildDataSet,
                size: 'small',
            }}
        />
    );
};
