import { PageContainer, ProCard, ProDescriptions } from '@ant-design/pro-components';
import { DownloadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Tooltip, Spin, Alert, Space, Flex } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useAuth } from 'providers/AuthProvider';
import { useTraceContext } from 'providers/TraceProvider';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import 'moment-timezone';
import moment from 'moment';
import { SessionInfoDto } from 'apis/TraceApi';
import { TracePlaybackSection } from 'components/TracePlaybackSection';
import { TraceSampleSection } from 'components/TraceSampleSection';
import { TraceTimelineSection } from 'components/TraceTimelineSection';
import { ConfigTableModule } from 'components/dialogs/ConfigTableDailog';

const sessionInfoSection = (traceInfo: SessionInfoDto) => {
    return (
        <ProDescriptions column={2} style={{ marginBlockEnd: -16 }}>
            <ProDescriptions.Item label="Trace ID" copyable>
                {traceInfo?.traceId ?? '-'}
            </ProDescriptions.Item>
            <ProDescriptions.Item label="App ID">{traceInfo?.appId ?? '-'}</ProDescriptions.Item>
            <ProDescriptions.Item label="Device ID" copyable>
                {traceInfo?.deviceId ?? '-'}
            </ProDescriptions.Item>
            <ProDescriptions.Item label="Date">
                {traceInfo?.date ? moment(traceInfo.date).format() : undefined}
            </ProDescriptions.Item>
        </ProDescriptions>
    );
};

const TraceSummarySection: React.FC<any> = (props) => {
    const { isSuperAdmin } = useAuth();
    const traceDetail = useTraceContext().traceDetail!;

    return (
        <ProCard
            title="Summary"
            headerBordered
            extra={
                isSuperAdmin ? <ConfigTableModule variables={traceDetail.rtConfig} /> : undefined
            }
        >
            <ProDescriptions column={3} dataSource={traceDetail.detail} layout="vertical">
                <ProDescriptions.Item
                    dataIndex="sdkVersion"
                    label="SDK version"
                    render={(_, entity) => {
                        return <Tooltip title={entity.sdkId}>{entity.sdkVersion}</Tooltip>;
                    }}
                />
                <ProDescriptions.Item
                    label="App"
                    render={(_, entity) => {
                        const appVer = entity.appVersion ? ` (${entity.appVersion})` : '';
                        return `${entity.appId}${appVer}`;
                    }}
                />
                <ProDescriptions.Item
                    label="Device"
                    render={(_, entity) => {
                        return <Tooltip title={entity.dId}>{entity.dModel}</Tooltip>;
                    }}
                />
                <ProDescriptions.Item
                    label="OS"
                    render={(_, entity) => {
                        if (entity.dOsType === 'AOS') {
                            return `Android API ${entity.dOsVersion}`;
                        } else if (entity.dOsType === 'IOS') {
                            return `iOS ${entity.dOsVersion}`;
                        } else {
                            const dOsType = entity.dOsType ? `${entity.dOsType} ` : '';
                            return `${dOsType}${entity.dOsVersion}`;
                        }
                    }}
                />
                <ProDescriptions.Item dataIndex="timeZone" label="Time Zone" span={2} />

                <ProDescriptions.Item
                    span={2}
                    label="Period"
                    render={(_, entity) => {
                        const dFrom = moment(entity.startAt).tz(entity.timeZone).format();
                        const dTo = moment(entity.endAt).tz(entity.timeZone).format();
                        return `${dFrom} ~ ${dTo}`;
                    }}
                />
                <ProDescriptions.Item
                    dataIndex="duration"
                    label="Duration"
                    render={(_, entity) => {
                        const twoDigit = (val: number) => (val < 10 ? `0${val}` : val);
                        const duration = moment.duration(entity.duration);
                        if (duration.hours() > 0) {
                            return `${twoDigit(duration.hours())}:${twoDigit(duration.minutes())}:${twoDigit(duration.seconds())}`;
                        } else {
                            return `${twoDigit(duration.minutes())}:${twoDigit(duration.seconds())}`;
                        }
                    }}
                />
            </ProDescriptions>
        </ProCard>
    );
};

const TraceDetailScreen: React.FC = () => {
    const { traceId } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();

    const { isSuperAdmin } = useAuth();
    const { fetchSession, traceInfo, traceDetail, isLoading, resetDetail } = useTraceContext();
    const [currTs, setCurrTs] = useState<number | undefined>();

    useEffect(() => {
        if (!!state?.traceId) {
            fetchSession(state);
        } else if (!!traceId) {
            fetchSession(traceId);
        }
        return () => resetDetail();
    }, []);

    function _humanFileSize(size: number) {
        var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return +(size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }

    function _renderContent() {
        if (!traceDetail) {
            return (
                <Alert
                    message="Trace not found"
                    description={
                        <>
                            Go <Link to="/analysis/session">Back</Link> to view another session.
                        </>
                    }
                    type="error"
                    showIcon
                    style={{ marginTop: 24 }}
                />
            );
        }
        return (
            <Flex vertical gap={12} style={{ marginTop: '1em' }}>
                <TraceSummarySection />
                <TracePlaybackSection onProgressChange={(idx, ts) => setCurrTs(ts)} />
                <TraceSampleSection ts={currTs} />
                <TraceTimelineSection ts={currTs} />
            </Flex>
        );
    }

    function _renderDownloadBtn() {
        if (traceDetail && isSuperAdmin) {
            const isLargeFile = traceDetail.detail.fileSize > 82935808;
            return (
                <Space>
                    <Tooltip placement="left" title={traceDetail.detail.fileName}>
                        <Button
                            icon={<DownloadOutlined />}
                            href={`${traceDetail.dlUrl}${isLargeFile ? '&format=gz' : ''}`}
                            target="_blank"
                        >
                            Download ({_humanFileSize(traceDetail.detail.fileSize)})
                        </Button>
                    </Tooltip>
                </Space>
            );
        } else {
            return undefined;
        }
    }

    return (
        <PageContainer
            title={`Session: ${traceId}`}
            ghost={true}
            breadcrumbRender={() => {
                return (
                    <Button
                        style={{ padding: 0 }}
                        type="link"
                        icon={<ArrowLeftOutlined />}
                        onClick={() => navigate(-1)}
                    >
                        Back
                    </Button>
                );
            }}
            extra={_renderDownloadBtn()}
            content={traceInfo ? sessionInfoSection(traceInfo) : undefined}
        >
            {isLoading ? (
                <Space size="large">
                    <Spin size="large" style={{ margin: 24 }} />
                </Space>
            ) : (
                _renderContent()
            )}
        </PageContainer>
    );
};

export default TraceDetailScreen;
