import 'mapbox-gl/dist/mapbox-gl.css';
import 'styles/map-style.css';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { LatLngDto } from 'apis/VenueApi';
import BaseMapEngine, { HK_CENTER } from 'services/BaseMapEngine';
import { LocationPickerProps, LocationPickerRef } from './LocationPicker';

const MapBoxLocationPicker = React.forwardRef<LocationPickerRef, LocationPickerProps>((props, ref) => {
    const mapEngineRef = useRef(new BaseMapEngine());
    const lastRadiusRef = useRef<number | undefined>();

    useEffect(() => {
        const initCenter = props.center ?? HK_CENTER;
        mapEngineRef.current.init('map-view', false, initCenter);
        lastRadiusRef.current = undefined;
    }, []);
    useEffect(() => {
        const initCenter = props.center ?? HK_CENTER;
        mapEngineRef.current.showCenterMarker(
            true,
            initCenter,
            props.onChange && props.editable !== false
                ? (center) => {
                      props.onChange!({ lat: center.lat, lng: center.lng });
                      updateRadius(center);
                  }
                : undefined,
            props.editable,
        );
    }, [props.center]);

    const updateRadius = (center: LatLngDto | null) => {
        if (!center) return;
        mapEngineRef.current.setAccuracyCircle({
            lat: center.lat,
            lng: center.lng,
            accuracy: lastRadiusRef.current,
        });
    };

    useImperativeHandle(ref, () => ({
        getCenter() {
            return mapEngineRef.current.getCenter();
        },
        flyTo(center) {
            return mapEngineRef.current.flyTo(center, false);
        },
        updateRadius(radius?: number) {
            lastRadiusRef.current = radius;
            if (!!radius && mapEngineRef.current.isReady())
                updateRadius(mapEngineRef.current.getCenter());
        },
    }));

    return <div id="map-view" style={{ width: '100%', height: '100%', ...props.style }}></div>;
});
export default MapBoxLocationPicker;
