import React, { useEffect, useRef } from 'react';
interface IActivityGraphProps {
    year: number;
    data: number[][];
    quartiles?: [number, number, number];
    cellSize?: number;
    cellRadius?: number;
    labelX?: boolean;
    labelY?: boolean;
    legend?: boolean;
    tooltips?: boolean;
    tooltipsFormatter?: (val: number) => string;
}

const ContributionGraph = (canvas: HTMLCanvasElement, options: IActivityGraphProps) => {
    const {
        year,
        data,
        quartiles = [25, 50, 75],
        cellRadius = 6,
        labelX = true,
        labelY = false,
        legend = true,
        tooltips = true,
    } = options;
    const ctx = canvas.getContext('2d');
    const dataMap = new Map<number, number>(data as any);

    canvas.width = canvas.parentElement!.clientWidth;

    // Configuration
    const weekCount = 53;
    const cellPadding = options.cellSize
        ? Math.max(2, options.cellSize * 0.08)
        : (canvas.width / weekCount) * 0.08;
    const cellSize = options.cellSize ?? (canvas.width - cellPadding * weekCount) / weekCount;
    const headerHeight = labelX ? Math.max(cellSize * 0.8, 14) + cellPadding : 0;
    const footerHeight = legend ? cellSize * 2 : 0;
    const labelWidth = labelY ? cellSize * 2 : cellPadding;
    const firstDay = new Date(year, 0, 1);
    const firstDayOffset = firstDay.getDay();

    // Colors for different levels
    const colors = {
        0: '#eceef1',
        1: '#9be8a7',
        2: '#40c361',
        3: '#30a24f',
        4: '#206e39',
    };

    // Set canvas size
    canvas.width = labelWidth + (cellSize + cellPadding) * weekCount;
    canvas.height = footerHeight + headerHeight + (cellSize + cellPadding) * 7;

    const getLevel = (value: number) => {
        if (!value || value <= 0) return 0;
        if (value <= quartiles[0]) return 1;
        if (value <= quartiles[1]) return 2;
        if (value <= quartiles[2]) return 3;
        return 4;
    };

    const getDateFromCoordinates = (x: number, y: number) => {
        const weekIndex = Math.floor((x - labelWidth) / (cellSize + cellPadding));
        const dayIndex = Math.floor((y - headerHeight) / (cellSize + cellPadding));
        if (weekIndex < 0 || dayIndex < 0 || weekIndex > weekCount || dayIndex > 6) return null;

        const firstDay = new Date(year, 0, 1);
        const dayOffset = weekIndex * 7 + dayIndex - firstDay.getDay();
        const date = new Date(year, 0, 1 + dayOffset);

        return date;
    };

    const getDayOfYear = (curr: Date) =>
        Math.floor((curr.getTime() - firstDay.getTime()) / (24 * 60 * 60 * 1000));

    const setupEventListeners = () => {
        const tooltip = document.getElementById('graph-tooltips')!;
        const scale = canvas.width / canvas.offsetWidth;
        canvas.addEventListener('mousemove', (e) => {
            const rect = canvas.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;

            const date = getDateFromCoordinates(x * scale, y * scale);
            if (date && date.getFullYear() === year) {
                const dayOfYear = getDayOfYear(date) + 1;
                const value = dataMap.get(dayOfYear) || 0;
                const strValue = options.tooltipsFormatter
                    ? options.tooltipsFormatter(value)
                    : value;
                tooltip.style.display = 'block';
                tooltip.style.left = x + 40 + 'px';
                tooltip.style.top = y + 70 + 'px';
                tooltip.textContent = `${date.toDateString()} ${strValue}`;
            } else {
                tooltip.style.display = 'none';
            }
        });

        canvas.addEventListener('mouseout', () => {
            tooltip.style.display = 'none';
        });
    };

    const render = () => {
        if (!ctx) return;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        const fontSize = headerHeight - cellPadding * 1.5;
        ctx.font = `${fontSize}px Arial`;
        ctx.fillStyle = '#666';
        ctx.textAlign = 'left';

        // Draw month labels
        if (labelX) {
            const months = [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
            ];
            months.forEach((month, index) => {
                const date = new Date(year, index, 1);
                const dayOfYear = getDayOfYear(date);
                const weekIndex = Math.floor((dayOfYear + firstDayOffset) / 7);
                const x = labelWidth + weekIndex * (cellSize + cellPadding);
                const y = fontSize - cellPadding * 2;
                ctx.fillText(month, x, y);
            });
        }

        // Draw day labels
        if (labelWidth > cellPadding && labelY) {
            const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            days.forEach((day, index) => {
                const y = headerHeight + index * (cellSize + cellPadding) + cellSize;
                ctx.fillText(day, cellPadding, y);
            });
        }

        // Draw contribution cells
        const totalDays = year % 4 === 0 ? 366 : 365;

        for (let day = 1; day <= totalDays; day++) {
            const date = new Date(year, 0, day);
            const dayOfWeek = date.getDay();
            const weekNumber = Math.floor((day + firstDayOffset - 1) / 7);

            const x = labelWidth + weekNumber * (cellSize + cellPadding);
            const y = headerHeight + dayOfWeek * (cellSize + cellPadding);

            const value = dataMap.get(day) || 0;
            const level = getLevel(value);

            ctx.fillStyle = colors[level];
            // ctx.roundRect(x, y, cellSize, cellSize, 50);

            // Add rounded corners
            ctx.beginPath();
            ctx.roundRect(x, y, cellSize, cellSize, cellRadius);
            ctx.fill();
        }

        // Draw legend
        if (legend) {
            const lgCellSize = cellSize * 0.8;
            const legendX = canvas.width - cellSize * 7;
            const legendY = canvas.height - lgCellSize * 1.5;
            const colorArr = Object.values(colors);
            let offsetX = legendX;
            for (let j = 0; j < colorArr.length; j++) {
                offsetX = legendX + (lgCellSize + cellPadding) * j;
                // Draw square
                ctx.fillStyle = colorArr[j];
                ctx.beginPath();
                ctx.roundRect(offsetX, legendY, lgCellSize, lgCellSize, cellRadius);
                ctx.fill();
            }
            // Draw label
            const lgFontSize = fontSize * 0.8;
            ctx.font = `${lgFontSize}px Arial`;
            ctx.fillStyle = '#666';
            ctx.fillText('Less', legendX - lgCellSize * 2, legendY + lgFontSize);
            ctx.fillText('More', offsetX + lgCellSize * 1.5, legendY + lgFontSize);
        }
    };

    if (tooltips) {
        // Setup event listeners
        setTimeout(() => {
            setupEventListeners();
        }, 1000);
    }

    // Initial render
    render();
};

const ActivityGraph: React.FC<IActivityGraphProps> = (props) => {
    const canvasRef = useRef<HTMLCanvasElement>();
    useEffect(() => {
        if (canvasRef.current) {
            ContributionGraph(canvasRef.current, props);
        }
    }, []);
    return (
        <div>
            <div id="graph-tooltips"></div>
            <canvas style={{ width: '100%' }} ref={canvasRef as any}></canvas>
        </div>
    );
};

export default React.memo(
    ActivityGraph,
    (prev, curr) => JSON.stringify(prev.data) === JSON.stringify(curr.data),
);
